

















































import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { RawLocation } from 'vue-router'
import DefaultMenu from '@template/components/defaultMenu.vue'
import DefaultMenuUser from '@template/components/defaultMenuUser.vue'
import { getModule } from 'vuex-module-decorators'
import axios from 'axios'
import VueAxios from 'vue-axios'
import SmallMenu from '@template/components/SmallMenu.vue'
import { IMenuItem, MenuItem, Separator } from '@template/classes/menu'
import DefaultTemplate from '@template/components/defaultTemplate.vue'
import LoginModule from '~/store/LoginModule'
import HorizontalTemplate from '~/template/horizontalTemplate.vue'
import StudyModule from '~/store/StudyModule'
import ComponentModule from '~/store/ComponentModule'
import { SurveyLinkModel } from '~/shared/model/Study/SurveyLinkModel'

Vue.use(VueAxios, axios)

@Component({
  components: { DefaultMenuUser, DefaultMenu, HorizontalTemplate, SmallMenu, DefaultTemplate }
  })
export default class Default extends Vue {
  /* Props */
  selectedTab = 'tab1'
  key =1
  fab = false
  items: [
      { text: 'Real-Time', icon: 'mdi-clock' },
      { text: 'Audience', icon: 'mdi-account' },
      { text: 'Conversions', icon: 'mdi-flag' },
    ]

  errorPopUpTimer:number = 8000
  errorPopUpText:string = ''
  showErrorPopup:boolean = false
  selectedSurvey: SurveyLinkModel = {} as SurveyLinkModel

  menuItems: IMenuItem[] = []

  get getAdminRights ():boolean {
    const appFunctions = this.loginStore.getApplicationFunctions
    if (appFunctions.filter(p => p === 'Data Steward').length > 0 || this.loginStore.user.admin) {
      return true
    }
    return false
  }

  get getSurveys () {
    return this.studyStore.surveyLinks.filter(p => p.canGotoSurvey)
  }

  get getHasSelectedStudy () {
    const studySelected = this.studyStore.selectedStudyId !== 0
    if (studySelected && !this.ShowBackToSearchStudy) {
      return true
    }
    return false
  }

  get ShowBackToSearchStudy () {
    if (this.$router.currentRoute.name === 'EditStudy') {
      return true
    }
    return false
  }

  /* Watch */
  get showError ():boolean {
    return this.loginStore.error?.errorCode > 0
  }

  @Watch('showError')
  onErrorChange () {
    if (this.loginStore.error.errorCode === 500) {
      this.showErrorPopup = true
      this.errorPopUpText = this.loginStore.error?.message?.fullErrorMessage
    }
  }

  /* Data */
  loginStore: LoginModule = getModule(LoginModule)
  studyStore:StudyModule = getModule(StudyModule)
  componentStore: ComponentModule = getModule(ComponentModule)

  /* Enums */
  setShowErrorPopUp (): void {
    if (this.loginStore.error.errorCode) { this.showErrorPopup = true }
    this.showErrorPopup = false
  }

  /* Lifecycle */
  async mounted () {
    await this.loginStore.getUserInfo()
    await this.loginStore.getAllowedFunctions()
    this.menuItems.push(
      new MenuItem({
        title: 'Study',
        subTitle: this.getStudySubTitle,
        disabled: false,
        subItems: this.getStudySubMenuItems,
        icon: 'mdi-book-open-variant',
        location: this.studyRoute
      })
    )
    this.menuItems.push(
      new MenuItem({
        title: 'Components',
        subTitle: this.getComponentSubTitle,
        disabled: false,
        subItems: this.getComponentSubMenuItems,
        icon: ' mdi-toy-brick-search',
        location: this.componentRoute
      })
    )
    this.menuItems.push(
      new MenuItem({
        title: 'Classifications',
        subTitle: 'Classifications manager',
        disabled: !this.getAdminRights,
        icon: 'mdi-format-list-bulleted',
        location: {
          name: 'ClassificationManager'
        }
      })
    )
    this.menuItems.push(
      new MenuItem({
        title: 'Common concepts',
        subTitle: 'Common concepts',
        disabled: !this.getAdminRights,
        icon: 'mdi-label-outline',
        location: {
          name: 'CommonConceptManager'
        }
      })
    )
    this.menuItems.push(new Separator())
    this.menuItems.push(
      new MenuItem({
        title: 'Admin',
        disabled: !this.getAdminRights,
        icon: 'mdi-cog ',
        location: {
          path: '/admin/ManageUsers'
        },
        subItems: this.getAdminSubMenuItems
      })
    )
  }

  /* Computed */

  /* Methods */
  studyRoute (): RawLocation {
    if (this.studyStore.studyHistory.length) {
      return this.studyStore.studyHistory[0].url
    }

    return {
      name: 'Study'
    }
  }

  componentRoute (): RawLocation {
    if (this.componentStore.componentHistory.length) {
      return this.componentStore.componentHistory[0].url
    }

    return {
      name: 'Search'
    }
  }

  getAdminSubMenuItems (): IMenuItem[] {
    const subItems: MenuItem[] = []
    subItems.push(
      new MenuItem({
        title: 'Users',
        location: {
          path: '/admin/ManageUsers'
        }
      })
    )
    subItems.push(
      new MenuItem({
        title: 'Permissions',
        subTitle: 'Manage application permissions',
        disabled: !this.getAdminRights,
        icon: 'mdi-cog',

        location: {
          path: '/admin/ManageApplicationRights'
        }
      })
    )
    subItems.push(
      new MenuItem({
        title: 'Roles',
        subTitle: 'Manage roles',
        disabled: !this.getAdminRights,
        icon: 'mdi-cog',

        location: {
          path: '/admin/ManageRoles'
        }
      })
    )

    return subItems
  }

  getStudySubMenuItems (): IMenuItem[] {
    if (!this.studyStore.selectedStudyId) {
      return []
    }

    const history: IMenuItem[] = this.studyStore.studyHistory
      .filter(s => s.studyId !== this.studyStore.selectedStudyId)
      .map((s) => {
        return new MenuItem({
          title: s.title,
          location: {
            name: 'EditStudy-studyId',
            params: {
              studyId: String(s.studyId)
            }
          }
        })
      })

    if (history.length) {
      history.push(new Separator())
    }

    return [
      ...history,
      new MenuItem({
        title: 'Search study',
        location: {
          name: 'Study'
        }
      })
    ]
  }

  getStudySubTitle (): string {
    if (this.studyStore.selectedStudyId === 0) {
      return 'Search study'
    }

    return this.studyStore.studyDetails.name
  }

  getComponentSubTitle (): string {
    if (Object.keys(this.componentStore.selected).length === 0) {
      return 'Harmonised components'
    }

    return this.componentStore.selectedComponentDesignator
  }

  getComponentSubMenuItems (): IMenuItem[] {
    if (!this.componentStore.selectedComponentId) {
      return []
    }

    const history: IMenuItem[] = this.componentStore.componentHistory
      .filter(c => c.componentId !== this.componentStore.selectedComponentId)
      .map((c) => {
        return new MenuItem({
          title: c.designator,
          location: {
            name: 'EditComponent-componentId',
            params: {
              componentId: String(c.componentId)
            }
          }
        })
      })

    if (history.length) {
      history.push(new Separator())
    }

    return [
      ...history,
      new MenuItem({
        title: 'Harmonised components',
        location: {
          name: 'Search'
        }
      })
    ]
  }

  clickEvent (tab:string) {
    this.selectedTab = tab
  }

  get isLoggedIn (): boolean {
    return !!this.loginStore.token.length
  }

  /* Events/Listeners */
  async onLogout () {
    await this.loginStore.logout()

    this.$router.push({
      name: 'auth-login'
    })
  }

  public navigateBackToStudy () {
    this.$router.push({
      name: 'EditStudy-studyId',
      params: {
        studyId: String(this.studyStore.selectedStudyId)
      }
    })
  }

  public GoToDisclaimer () {
    this.selectedTab = 'tab4'
    const linkAdres = 'https://www.wur.nl/en/Disclaimer.htm'
    window.open(linkAdres, '_blank')
  }

  public navigateSearchToStudy () {
    this.$router.push({
      name: 'Study'
    })
  }

  public GoToSelectedSurvey (surveyId:number) {
    this.selectedSurvey = {} as SurveyLinkModel
    this.$router.push({
      path: '/EditSurvey',
      query: {
        surveyId: String(surveyId)
      }
    })
  }
}
