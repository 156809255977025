import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import CdpTypeValueModel from '~/shared/model/CdpTypeValueModel'
import ComponentMetadataPropertyModel from '~/shared/model/ComponentMetadataPropertyModel'
import QuestionPropertyModel from '~/shared/model/QuestionPropertyModel'
import PropertyValueTypeModel from '~/shared/model/PropertyValueTypeModelLookUp'
import { MainStore } from '~/store/index'
import { PropertyService } from '~/shared/service/property.service'
import AddCdpTypeValueModel from '~/shared/model/AddCdpTypeValueModel'

@Module({ namespaced: true, name: 'PropertyModule', dynamic: true, store: MainStore })

export default class PropertyModule extends VuexModule {
    componentCdpTypeValues: Array<CdpTypeValueModel> = [] as Array<CdpTypeValueModel>
    questionCdpTypeValues: Array<CdpTypeValueModel> = [] as Array<CdpTypeValueModel>
    questionProperties: Array<QuestionPropertyModel> = [] as Array<QuestionPropertyModel>
    componentMetadataProperties: Array<ComponentMetadataPropertyModel> = [] as Array<ComponentMetadataPropertyModel>
    propertyValueTypes: Array<PropertyValueTypeModel> = [] as Array<PropertyValueTypeModel>

    @Mutation
    addAllComponentCdpTypeValues (cdpTypeValues:Array<CdpTypeValueModel>) {
      this.componentCdpTypeValues = cdpTypeValues
    }

    @Mutation
    addAllQuestionCdpTypeValues (cdpTypeValues:Array<CdpTypeValueModel>) {
      this.questionCdpTypeValues = cdpTypeValues
    }

    @Mutation
    addQuestionPropertiesByCdpTypeValue (questionProperties:Array<QuestionPropertyModel>) {
      this.questionProperties = questionProperties
    }

    @Mutation
    addComponentPropertiesByCdpTypeValue (componentMetadataProperties:Array<ComponentMetadataPropertyModel>) {
      this.componentMetadataProperties = componentMetadataProperties
    }

    @Mutation
    addAllPropertyValueTypes (propertyValueTypes:Array<PropertyValueTypeModel>) {
      this.propertyValueTypes = propertyValueTypes
    }

    @Action
    async getAllComponentCdpTypeValues (): Promise<void> {
      await PropertyService.getAllComponentCdpTypeValues()
        .then((resp) => {
          this.addAllComponentCdpTypeValues(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async getAllQuestionCdpTypeValues (): Promise<void> {
      await PropertyService.getAllQuestionCdpTypeValues()
        .then((resp) => {
          this.addAllQuestionCdpTypeValues(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async getQuestionPropertiesByCdpTypeValue (cdpTypValueId: number): Promise<void> {
      await PropertyService.getQuestionPropertiesByCdpTypeValue(cdpTypValueId)
        .then((resp) => {
          this.addQuestionPropertiesByCdpTypeValue(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async getComponentMetadataPropertiesByCdpTypeValue (cdpTypValueId: number): Promise<void> {
      await PropertyService.getComponentPropertiesByCdpTypeValue(cdpTypValueId)
        .then((resp) => {
          this.addComponentPropertiesByCdpTypeValue(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async getAllPropertyValueTypes (): Promise<void> {
      await PropertyService.getAllPropertyValueTypesLookUp()
        .then((resp) => {
          this.addAllPropertyValueTypes(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async AddCdpComponentValueType (cdpTypeValue: AddCdpTypeValueModel): Promise<void> {
      await PropertyService.AddCdpComponentValueType(cdpTypeValue)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async AddCdpQuestionValueType (CdpTypeValue: AddCdpTypeValueModel): Promise<void> {
      await PropertyService.AddCdpQuestionValueType(CdpTypeValue)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async UpdateCdpValueType (CdpTypeValue: CdpTypeValueModel): Promise<void> {
      await PropertyService.UpdateCdpValueType(CdpTypeValue)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async DeleteCdpValueType (Id: number): Promise<void> {
      await PropertyService.DeleteCdpValueType(Id)
        .then()
        .catch((error) => {
          // return error.response;
          throw error
        })
    }

    @Action
    async AddComponentMetadataProperty (componentMetadataProperty: ComponentMetadataPropertyModel): Promise<void> {
      await PropertyService.AddComponentMetadataProperty(componentMetadataProperty)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async UpdateComponentMetadataProperty (componentMetadataProperty: ComponentMetadataPropertyModel): Promise<void> {
      await PropertyService.UpdateComponentMetadataProperty(componentMetadataProperty)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async DeleteComponentMetadataProperty (Id: number): Promise<void> {
      await PropertyService.DeleteComponentMetadataProperty(Id)
        .then()
        .catch((error) => {
          // return error.response;
          throw error
        })
    }

    @Action
    async AddQuestionProperty (questionProperty: QuestionPropertyModel): Promise<void> {
      await PropertyService.AddQuestionProperty(questionProperty)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async UpdateQuestionProperty (questionProperty: QuestionPropertyModel): Promise<void> {
      await PropertyService.UpdateQuestionProperty(questionProperty)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async DeleteQuestionProperty (Id: number): Promise<void> {
      await PropertyService.DeleteQuestionProperty(Id)
        .then()
        .catch((error) => {
          // return error.response;
          throw error
        })
    }
}
