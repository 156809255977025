import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ApplicationRoleModel from '~/shared/model/ApplicationRoleModel'
import RoleModel from '~/shared/model/RoleModel'
import { MainStore } from '~/store/index'
import { RoleService } from '~/shared/service/role.service'

@Module({ namespaced: true, name: 'RoleModule', dynamic: true, store: MainStore })
export default class RoleModule extends VuexModule {
    applicationRoles: Array<ApplicationRoleModel> = [] as Array<ApplicationRoleModel>
    roles: Array<RoleModel> = []

    @Mutation
    addAllApplicationRoles (roles:Array<ApplicationRoleModel>) {
      this.applicationRoles = roles
    }

    @Mutation
    setRoles (roles: Array<RoleModel>) {
      this.roles = roles
    }

    @Action
    async getAllApplicationRoles (): Promise<void> {
      await RoleService.getAllApplicationRoles()
        .then((resp) => {
          this.addAllApplicationRoles(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async AddApplicationRole (RoleName: string): Promise<void> {
      await RoleService.AddApplicationRole(RoleName)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async DeleteRole (roleId: number): Promise<void> {
      await RoleService.DeleteRole(roleId)
        .then()
        .catch((error) => {
          // return error.response;
          throw error
        })
    }

    @Action
    async GetStudyRoles (): Promise<void> {
      await RoleService.GetStudyRoles()
        .then((resp) => {
          this.setRoles(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async AddStudyRole (RoleName: string): Promise<void> {
      await RoleService.AddStudyRole(RoleName)
        .catch((error) => {
          throw error
        })
    }
}
