import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VAppBar,{staticClass:"navigation-bar",attrs:{"app":"","clipped-left":"","dense":""}},[_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.isLoadingAny)?_c(VProgressLinear,{attrs:{"absolute":"","top":"","active":"","indeterminate":"","color":"#34b233"}}):_vm._e()],1),_vm._v(" "),_c('nuxt-link',{attrs:{"to":"/Search"}},[_c(VImg,{attrs:{"src":"/wur.png","width":"210"}})],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_vm._t("header")],2),_vm._v(" "),_c(VMain,[_c(VContainer,{staticClass:"main-container",attrs:{"fluid":""}},[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }