import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { SurveyModel } from '~/shared/model/SurveyModel'
import { MainStore } from '~/store/index'
import { SurveyService } from '~/shared/service/survey.service'
import SurveyUserModel from '~/shared/model/SurveyUserModel'
import SurveyUserRoleModel from '~/shared/model/SurveyUserRoleModel'
import { ClassificationService } from '~/shared/service/classification.service'
import ClassificationModel from '~/shared/model/ClassificationModel'

@Module({ namespaced: true, name: 'SurveyModule', dynamic: true, store: MainStore })
export default class SurveyModule extends VuexModule {
  surveys: Array<SurveyModel> = [] as Array<SurveyModel>
  surveyUsers:Array<SurveyUserModel> = [] as Array<SurveyUserModel>
  surveyUserRoles:Array<SurveyUserRoleModel> = [] as Array<SurveyUserRoleModel>
  availableUsers:Array<SurveyUserModel> = [] as Array<SurveyUserModel>
  availableUserRoles:Array<SurveyUserRoleModel> = [] as Array<SurveyUserRoleModel>
  selectedSurvey:SurveyModel = new SurveyModel()
  selectedSurveyUser:SurveyUserModel = {} as SurveyUserModel

  mySurveys: Array<SurveyModel> = [] as Array<SurveyModel>

  @Mutation
  setMySurveys (surveys:Array<SurveyModel>) {
    this.mySurveys = surveys
  }

  @Mutation
  setSurveys (surveys:Array<SurveyModel>) {
    this.surveys = surveys
  }

  @Mutation
  setSurveyUsers (surveyUsers:Array<SurveyUserModel>) {
    this.surveyUsers = surveyUsers
  }

  @Mutation
  clearSelectedSurveyUser () {
    this.selectedSurveyUser = {} as SurveyUserModel
  }

  @Mutation
  setSurveyUserRoles (surveyUserRoles:Array<SurveyUserRoleModel>) {
    this.surveyUserRoles = surveyUserRoles
  }

  @Mutation
  setAvailableUsers (surveyUsers:Array<SurveyUserModel>) {
    // clear current list
    this.availableUsers = []

    // Filter out the users that are already assigned to this survey
    if (this.surveyUsers.length > 0) {
      surveyUsers.forEach((u) => {
        const exist = this.surveyUsers.find((e) => {
          return e.userId === u.userId
        })

        if (!exist) {
          this.availableUsers.push(u)
        }
      })
    } else {
      this.availableUsers = surveyUsers
    }
  }

  @Mutation
  setSelectedSurvey (survey:SurveyModel) {
    this.selectedSurvey = survey
  }

  @Mutation
  setSelectedSurveyUser (user:SurveyUserModel) {
    this.selectedSurveyUser = user
  }

  @Mutation
  setAvailableRoles (surveyUserRoles:Array<SurveyUserRoleModel>) {
    this.availableUserRoles = []
    // Filter ot the roles already assinged to this user
    if (this.surveyUserRoles.length > 0) {
      surveyUserRoles.forEach((u) => {
        const exist = this.surveyUserRoles.find((e) => {
          return e.roleId === u.roleId
        })

        if (!exist) {
          this.availableUserRoles.push(u)
        }
      })
    } else {
      this.availableUserRoles = surveyUserRoles
    }
  }

  @Action
  async deleteSurvey (surveyId:number):Promise<void> {
    await SurveyService.deleteSurvey(surveyId)
      .then((resp) => {
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getAllSurveys (): Promise<void> {
    await SurveyService.getAllSurveys()
      .then((resp) => {
        this.setSurveys(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getSurveyUsers (survey: SurveyModel): Promise<void> {
    await SurveyService.getSurveyUsers(survey)
      .then((resp) => {
        this.setSurveyUsers(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getSurveyUserRoles (user:SurveyUserModel): Promise<void> {
    await SurveyService.getSurveyUserRoles(user)
      .then((resp) => {
        this.setSurveyUserRoles(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getAvailableUsers (): Promise<void> {
    await SurveyService.getAvailableUsers()
      .then((resp) => {
        this.setAvailableUsers(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getAvailableUserRoles (): Promise<void> {
    await SurveyService.getAvailableRoles()
      .then((resp) => {
        this.setAvailableRoles(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async addSurveyUserRole (role:SurveyUserRoleModel): Promise<void> {
    role.surveyId = this.selectedSurvey.surveyId
    role.userId = this.selectedSurveyUser.userId

    await SurveyService.addSurveyUserRole(role)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async addSurveyUser (user: SurveyUserModel): Promise<void> {
    user.surveyId = this.selectedSurvey.surveyId

    await SurveyService.addSurveyUser(user)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteSurveyUser (user: SurveyUserModel): Promise<void> {
    user.surveyId = this.selectedSurvey.surveyId

    await SurveyService.deleteSurveyUser(user).then(() => {
      this.clearSelectedSurveyUser()
    })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteSurveyUserRole (role: SurveyUserRoleModel): Promise<void> {
    role.surveyId = this.selectedSurvey.surveyId
    role.userId = this.selectedSurveyUser.userId

    await SurveyService.deleteSurveyUserRole(role)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getMySurveys (): Promise<void> {
    await SurveyService.getMySurveys()
      .then((resp) => {
        this.setMySurveys(resp)
      })
      .catch((error) => {
        throw error
      })
  }
}
