import Axios from 'axios'
import { ApiUrl } from '../util/api.url'
import { BaseService } from './base.service'
import ApplicationUserInRoleModel from '~/shared/model/ApplicationUserInRoleModel'
import ApplicationRoleUserModel from '~/shared/model/ApplicationRoleUserModel'

export class ApplicationRoleService extends BaseService {
  private static baseUrl = ApiUrl.webapiurl() + '/ApplicationRole/'

  static async getAllApplicationUsersInRole (roleId: number): Promise<Array<ApplicationUserInRoleModel>> {
    const param = {
      RoleId: roleId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetAllApplicationUsersInRole/', param)
    return response.data
  }

  static async AddApplicationRoleUser (applicationUser: ApplicationRoleUserModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'AddApplicationRoleUser', applicationUser)
    return response.data
  }

  static async DeleteApplicationRoleUser (applicationUser: ApplicationRoleUserModel): Promise<void> {
    const param = {
      userId: applicationUser.userId,
      roleId: applicationUser.roleId
    }
    const response = await this.deleteApiCall(this.baseUrl + 'DeleteApplicationRoleUser', param)
    return response.data
  }
}
