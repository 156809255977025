import Axios from 'axios'
import { ApiUrl } from '../util/api.url'
import ComponentAnswerModel from '../model/ComponentAnswerModel'
import ComponentAnswerOptionExtendedModel from '../model/ComponentAnswerOptionExtendedModel'
import ScaleSummaryModel from '../model/ScaleSummaryModel'
import SurveyAnswerModel from '../model/SurveyAnswerModel'
import SurveyAnswerOptionExtendedModel from '../model/SurveyAnswerOptionExtendedModel'
import { BaseService } from './base.service'

export class AnswerService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/Answer/'

    static async saveAnswer (answer:ComponentAnswerModel): Promise<Array<void>> {
      const response = await this.postApiCall(this.baseUrl + 'SaveAnswer', answer)
      return response.data
    }

    static async saveSurveyAnswer (answer:SurveyAnswerModel): Promise<Array<void>> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSurveyAnswer', answer)
      return response.data
    }

    static async saveCopyScale (scaleSummary:ScaleSummaryModel): Promise<Array<void>> {
      const response = await this.postApiCall(this.baseUrl + 'SaveCopyScale', scaleSummary)
      return response.data
    }

    static async saveSurveyCopyScale (scaleSummary:ScaleSummaryModel): Promise<Array<void>> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSurveyCopyScale', scaleSummary)
      return response.data
    }

    static async saveAnswerExtended (questionExtended: ComponentAnswerOptionExtendedModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveScale', questionExtended)
      return response.data
    }

    static async saveSurveyAnswerExtended (questionExtended: SurveyAnswerOptionExtendedModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSurveyScale', questionExtended)
      return response.data
    }

    static async deleteAnswer (answerId:number): Promise<Array<void>> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteAnswer', { answerId: answerId })
      return response.data
    }

    static async deleteSurveyAnswer (answerId:number): Promise<Array<void>> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteSurveyAnswer', { answerId: answerId })
      return response.data
    }

    static async getAnswerTypesAsync () {
      const response = await this.getApiCall(this.baseUrl + 'GetAnswerTypes')
      return response.data
    }

    static async getScaleOptionsAsync (componentId:number, contextId:number) {
      const response = await this.getApiCall(`${this.baseUrl}GetScaleOptions?componentId=${componentId}`)
      return response.data
    }

    static async getSurveyScaleOptionsAsync (surveyId:number, contextId:number) {
      const response = await this.getApiCall(`${this.baseUrl}GetSurveyScaleOptions?surveyId=${surveyId}`)
      return response.data
    }
}
