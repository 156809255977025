import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IDwhClassification } from '@fb-it/vue-dwh/src/types/classifications'
import { IDwhContextListItem } from '@fb-it/vue-dwh/src/types/contexts'
import { DwhClassificationListItem } from '~/shared/model/classifcationManager/DwhClassificationListItem'
import { DwhClassificationElement } from '~/shared/model/classifcationManager/DwhClassificationElement'
import { ClassificationService } from '~/shared/service/classification.service'
import ClassificationRequestModel from '~/shared/model/ClassificationRequestModel'
import { MainStore } from '~/store/index'
import { DWHCommonConceptModel } from '~/shared/model/classifcationManager/DWHCommonConceptModel'
import { CommonConceptService } from '~/shared/service/commonconcept.service'
import { CommonConceptsAndReferencesRequest } from '~/shared/model/classifcationManager/CommonConceptsAndReferencesRequest'
import DWHContext from '~/classes/DWHContext'
import { AddcommonConceptRequestModel } from '~/shared/model/classifcationManager/AddcommonConceptRequestModel'
import { ClassificationElementModel } from '~/shared/model/classifcationManager/ClassificationElementModel'

@Module({ namespaced: true, name: 'ClassificationModule', dynamic: true, store: MainStore })
export default class ClassificationModule extends VuexModule {
classificationList: Array<DwhClassificationListItem> = [] as Array<DwhClassificationListItem>
classificationElements: Array<DwhClassificationElement> = new Array<DwhClassificationElement>()
commonConceptsAndReferences: Array<DWHCommonConceptModel> = [] as Array<DWHCommonConceptModel>
contexts: Array<IDwhContextListItem> = [] as Array<IDwhContextListItem>

get getClassificationList () {
  return this.classificationList
}

@Mutation
setClassificationList (classifications:Array<DwhClassificationListItem>) {
  this.classificationList = classifications
}

@Mutation
setClassificationElements (classificationElements: Array<ClassificationElementModel>) {
  this.classificationElements = classificationElements
}

@Mutation
setCommonConceptsAndReferences (commonConcepts:Array<DWHCommonConceptModel>) {
  this.commonConceptsAndReferences = commonConcepts
}

@Mutation
setContexts (contexts:Array<IDwhContextListItem>) {
  this.contexts = contexts
}

@Action
async getAllClassifications (contextId:number): Promise<void> {
  await ClassificationService.GetAllClassifications(contextId)
    .then((resp) => {
      this.setClassificationList(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async getClassificationElements (classificationRequest: ClassificationRequestModel): Promise<void> {
  await ClassificationService.GetClassificationElementsWithContextDetails(classificationRequest)
    .then((resp) => {
      this.setClassificationElements(resp)
    })
}

@Action
async GetCommonConceptsAndReferences (request:CommonConceptsAndReferencesRequest): Promise<void> {
  await CommonConceptService.GetCommonConceptsAndReferences(request)
    .then((resp) => {
      this.setCommonConceptsAndReferences(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async GetAllContexts (): Promise<void> {
  await ClassificationService.GetAllContexts()
    .then((resp) => {
      this.setContexts(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async CreateNewCommonConcept (request:AddcommonConceptRequestModel): Promise<number> {
  return await CommonConceptService.CreateNewCommonConcept(request)
    .catch((error) => {
      throw error
    })
}
}
