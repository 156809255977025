import Axios from 'axios'
import RoleFunctionModel from '../model/RoleFunctionModel'
import { ApiUrl } from '../util/api.url'
import { BaseService } from './base.service'
import RoleFunctionInRoleModel from '~/shared/model/RoleFunctionInRoleModel'

export class RoleFunctionService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/RoleFunction/'

    static async getAllFunctionsInRole (roleId: number): Promise<Array<RoleFunctionInRoleModel>> {
      const params = {
        RoleId: roleId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetAllFunctionsInRole', params)
      return response.data
    }

    static async AddRoleFunction (roleFunction: RoleFunctionModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'AddRoleFunction', roleFunction)
      return response.data
    }

    static async DeleteRoleFunction (roleFunction: RoleFunctionModel): Promise<void> {
      const param = {
        roleId: roleFunction.roleId,
        functionId: roleFunction.functionId
      }
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteRoleFunction', param)
      return response.data
    }
}
