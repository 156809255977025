import _ from 'lodash'

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class LanguageTabModel {
  private _id: ValueOrFactory<number>
  private _value: ValueOrFactory<string>
  private _short: ValueOrFactory<string>

  get id (): number {
    return this.getValue(this._id)
  }

  get value (): string {
    return this.getValue(this._value)
  }

  get short (): string {
    return this.getValue(this._short)
  }

  constructor (init?: PartialValueOrFactory<LanguageTabModel>) {
    (Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T> (value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
