










import { Component, Vue } from 'nuxt-property-decorator'
import DefaultTemplate from '@template/components/defaultTemplate.vue'
import { getModule } from 'vuex-module-decorators'
import axios from 'axios'
import VueAxios from 'vue-axios'
import LoginModule from '~/store/LoginModule'

Vue.use(VueAxios, axios)

@Component({
  components: { DefaultTemplate }
})
export default class Default extends Vue {
  /* Props */

  /* Watch */

  /* Data */
  loginStore: LoginModule = getModule(LoginModule)

  /* Enums */

  /* Lifecycle */

  /* Computed */

  /* Methods */

  get isLoggedIn (): boolean {
    return !!this.loginStore.token.length
  }
}
