import { Guid } from 'guid-typescript'
import _ from 'lodash'
import { ComponentType } from '../enum/ComponentType'

export interface ISearchGridModel {
  id: string
}

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class SearchGridModel {
  private _id: ValueOrFactory<number>
  private _designator: ValueOrFactory<string>
  private _category: ValueOrFactory<string>
  private _author: ValueOrFactory<string>
  private _construct: ValueOrFactory<string>
  private _componentType: ValueOrFactory<ComponentType>
  private _keywords: ValueOrFactory<Array<string>>

  get id (): number {
    return this.getValue(this._id)
  }

  get designator (): string {
    return this.getValue(this._designator)
  }

  get author (): string {
    return this.getValue(this._author)
  }

  get category (): string {
    return this.getValue(this._category)
  }

  get construct (): string {
    return this.getValue(this._construct)
  }

  get componentType (): ComponentType {
    return this.getValue(this._componentType)
  }

  get keywords (): Array<string> {
    return this.getValue(this._keywords)
  }

  constructor (init?: PartialValueOrFactory<SearchGridModel>) {
    (Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T> (value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
