import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import _ from 'lodash'
import ComponentExtendedModel from '~/shared/model/ComponentExtendedModel'
import { MainStore } from '~/store/index'
import { SurveyService } from '~/shared/service/survey.service'
import TreeViewItem from '~/shared/model/ui-specific/TreeViewItem'
import { TreeNodeType } from '~/shared/enum/TreeNodeType'
import CdpTypeValueModel from '~/shared/model/CdpTypeValueModel'
import { PropertyValueTypeModel } from '~/shared/model/PropertyValueTypeModel'
import EditSurveyModel from '~/shared/model/EditSurveyModel'
import SurveyDimensionModel from '~/shared/model/SurveyDimensionModel'
import SurveyComponentDimensionQuestionUIModel from '~/shared/model/ui-specific/SurveyComponentDimensionQuestionUIModel'
import SurveyQuestionExtendedModel from '~/shared/model/SurveyQuestionExtendedModel'
import { SurveyQuestionService } from '~/shared/service/surveyquestion.service'
import SurveyQuestionModel from '~/shared/model/SurveyQuestionModel'
import { SurveyDimensionService } from '~/shared/service/surveydimension.service'
import SurveyDimensionExtendedModel from '~/shared/model/SurveyDimensionExtendedModel'
import SurveyComponentModel from '~/shared/model/SurveyComponentModel'
import { SurveyComponentService } from '~/shared/service/surveycomponent.service'
import SurveyComponentExtendedModel from '~/shared/model/SurveyComponentExtendedModel'
import { CreateSurveyComponentModel } from '~/shared/model/CreateSurveyComponentModel'
import SurveyExportModel from '~/shared/model/SurveyExportModel'
import { AnswerService } from '~/shared/service/answer.service'
import SurveyAnswerModel from '~/shared/model/SurveyAnswerModel'
import SurveyComponentRuleModel from '~/shared/model/SurveyComponentRuleModel'
import SurveyAnswerOptionExtendedModel from '~/shared/model/SurveyAnswerOptionExtendedModel'
import ScaleSummaryModel from '~/shared/model/ScaleSummaryModel'
import ClassificationModel from '~/shared/model/ClassificationModel'
import { ClassificationService } from '~/shared/service/classification.service'
import SurveyClassificationModel from '~/shared/model/SurveyClassificationModel'
import SurveyClassificationElementModel from '~/shared/model/SurveyClassificationElementModel'

@Module({ namespaced: true, name: 'EditSurveyModule', dynamic: true, store: MainStore })
export default class EditSurveyModule extends VuexModule {
  componentExtendeds: Array<SurveyComponentExtendedModel> = [] as Array<SurveyComponentExtendedModel>
  selected:SurveyComponentDimensionQuestionUIModel = {} as SurveyComponentDimensionQuestionUIModel
  selectedType:TreeNodeType = {} as TreeNodeType
  scales : Array<ScaleSummaryModel> = [] as Array<ScaleSummaryModel>
  cdpQuestionTypes:Array<CdpTypeValueModel>= [] as Array<CdpTypeValueModel>
  propertyValueTypes: Array<PropertyValueTypeModel> = [] as Array<PropertyValueTypeModel>
  surveyWithComponents:EditSurveyModel = new EditSurveyModel()
  classifications:Array<ClassificationModel>= [] as Array<ClassificationModel>
  surveyComponentRuleSelectedComponentQuestionId: number|null = null
  customClassifications: Array<SurveyClassificationModel> = [] as Array<SurveyClassificationModel>
  selectedSurveyId:number =0

  get getSurveyComponentRuleSelectedComponentQuestionId () {
    return this.surveyComponentRuleSelectedComponentQuestionId
  }

  @Mutation
  setSelected (selected:SurveyComponentDimensionQuestionUIModel) {
    this.selected = selected
  }

  @Mutation
  updateSelectedSurveyId (surveyId: number) {
    this.selectedSurveyId = surveyId
  }

  @Mutation
  setClassifications (classifications:Array<ClassificationModel>) {
    this.classifications = classifications
  }

  @Mutation
  addSurveyWithComponents (survey:EditSurveyModel) {
    this.surveyWithComponents = survey
  }

  @Mutation
  setScales (scales:Array<ScaleSummaryModel>) {
    this.scales = scales
  }

  @Mutation
  setSurveyComponentRuleSelectedComponentQuestionId (componentQuestionId: number) {
    this.surveyComponentRuleSelectedComponentQuestionId = componentQuestionId
  }

  @Mutation
  setCustomClassifications (customClassifications: Array<SurveyClassificationModel>) {
    this.customClassifications = customClassifications
  }

  @Action
  async saveCopyScale (scaleSummary: ScaleSummaryModel): Promise<void> {
    await AnswerService.saveSurveyCopyScale(scaleSummary)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getSurveyWithComponents (surveyId:number): Promise<void> {
    await SurveyService.getSurveyWithComponents(surveyId)
      .then((resp) => {
        this.addSurveyWithComponents(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getClassificationsAsync ():Promise<ClassificationModel> {
    const response = await ClassificationService.GetAllClassificationsForAnswer(this.surveyWithComponents.contextId)
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setClassifications(response)
    return response
  }

  @Action
  async getScalesAsync (surveyId:number, contextId:number):Promise<ScaleSummaryModel> {
    const response = await AnswerService.getSurveyScaleOptionsAsync(surveyId, contextId)
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setScales(response)
    return response
  }

  // Generate Survey tree
  @Action
  getSurveyTreeView (): Array<TreeViewItem> {
    const treeViewItems: Array<TreeViewItem> = new Array<TreeViewItem>(0)
    // Survey tree item
    const survey = this.surveyWithComponents
    if (survey) {
      const surveyTreeItem:TreeViewItem = {
        id: `${survey.surveyId}`,
        parentId: null,
        expanded: true,
        selected: false,
        class: 'survey',
        name: survey.designator,
        treeNodeType: TreeNodeType.Survey,
        items: new Array<TreeViewItem>(0),
        position: 1 // default
      }

      if (survey.surveyComponents?.length > 0) {
        survey.surveyComponents.forEach((component) => {
          // Will store all dimension and componentLevel questions and order them afterwards
          let componentTreeItemsTemp:Array<TreeViewItem> = new Array<TreeViewItem>(0)

          const componentTreeItem:TreeViewItem = {
            id: `${survey.surveyId}_component_${component.componentId}`,
            parentId: `${survey.surveyId}`,
            expanded: false,
            selected: false,
            class: 'component',
            name: component.designator,
            treeNodeType: TreeNodeType.Component,
            items: new Array<TreeViewItem>(0),
            position: component.position
          }
          if (component.componentDimensions) {
            // Add dimenstion items to compont treeview item
            component.componentDimensions.forEach((dimension:SurveyDimensionModel) => {
              const dimensionItem: TreeViewItem = {
                id: `${component.componentId}_dimension_${dimension.componentDimensionId}`,
                parentId: `${component.componentId}`,
                class: 'dimension',
                name: `${dimension.designator}`,
                treeNodeType: TreeNodeType.Dimension,
                expanded: false,
                selected: false,
                items: new Array<TreeViewItem>(0),
                position: dimension.position
              }

              // Add question to dimension treeview item
              if (dimension.componentQuestions) {
                const questions = dimension.componentQuestions
                questions.forEach((question) => {
                  const questionItem:TreeViewItem = {
                    id: `${component.componentId}_dimension_${dimension.componentDimensionId}_question_${question.componentQuestionId}`,
                    parentId: `${dimensionItem.id}`,
                    class: 'question',
                    name: question.name,
                    treeNodeType: TreeNodeType.Question,
                    expanded: false,
                    selected: false,
                    items: new Array<TreeViewItem>(0),
                    position: question.position
                  }
                  question.componentAnswerOptions?.forEach((comAnswer) => {
                    const answerItem:TreeViewItem = {
                      // eslint-disable-next-line max-len
                      id: `${component.componentId}_dimension_${dimension.componentDimensionId}_question_${question.componentQuestionId}_answerOption_${comAnswer.componentAnswerOptionId}`,
                      parentId: questionItem.id,
                      class: 'answerOption',
                      name: comAnswer.name,
                      treeNodeType: TreeNodeType.Answer,
                      expanded: false,
                      selected: false,
                      items: new Array<TreeViewItem>(0),
                      position: comAnswer.position
                    }
                    questionItem.items?.push(answerItem)
                    questionItem.items = _.orderBy(questionItem.items, ['position'], ['asc'])
                  })
                  dimensionItem.items?.push(questionItem)
                  dimensionItem.items = _.orderBy(dimensionItem.items, ['position'], ['asc'])
                })
              }
              componentTreeItemsTemp.push(dimensionItem)
            })
          }
          const componentQuestions = component.componentQuestions?.filter((q) => {
            return !q.componentDimensionId
          })

          // Add root level component question
          if (componentQuestions) {
            componentQuestions.forEach((comQuestion) => {
              const questionItem:TreeViewItem = {
                id: `${component.componentId}_question_${comQuestion.componentQuestionId}`,
                parentId: componentTreeItem.id,
                class: 'question',
                name: comQuestion.name,
                treeNodeType: TreeNodeType.Question,
                expanded: false,
                selected: false,
                items: new Array<TreeViewItem>(0),
                position: comQuestion.position
              }
              comQuestion.componentAnswerOptions?.forEach((comAnswer) => {
                // eslint-disable-next-line max-len
                const answerId = `${component.componentId}_question_${comQuestion.componentQuestionId}_answerOption_${comAnswer.componentAnswerOptionId}`
                const answerItem:TreeViewItem = {
                  // eslint-disable-next-line max-len
                  id: answerId,
                  parentId: questionItem.id,
                  class: 'answerOption',
                  name: comAnswer.name,
                  treeNodeType: TreeNodeType.Answer,
                  expanded: false,
                  selected: false,
                  items: new Array<TreeViewItem>(0),
                  position: comAnswer.position
                }
                questionItem.items?.push(answerItem)
                questionItem.items = _.orderBy(questionItem.items, ['position'], ['asc'])
              })
              componentTreeItemsTemp.push(questionItem)
            })
          }
          // Order by position
          componentTreeItemsTemp = _.orderBy(componentTreeItemsTemp, ['position'], ['asc'])
          // add titem to component
          componentTreeItemsTemp.forEach((i) => {
            componentTreeItem.items?.push(i)
          })
          surveyTreeItem.items?.push(componentTreeItem)
          surveyTreeItem.items = _.orderBy(surveyTreeItem.items, ['position'], ['asc'])
        })
      }

      treeViewItems.push(surveyTreeItem)
    }
    return treeViewItems
  }

  @Action
  async saveDimension (dimension: SurveyDimensionModel): Promise<number> {
    const response = await SurveyDimensionService.saveDimension(dimension)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
    return response
  }

  @Action
  async saveDimensionExtended (dimensionExtended: SurveyDimensionExtendedModel): Promise<void> {
    await SurveyDimensionService.saveDimensionExtended(dimensionExtended)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteDimension (dimensionId: number): Promise<void> {
    await SurveyDimensionService.deleteDimension(dimensionId)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveAnswer (answer: SurveyAnswerModel): Promise<void> {
    await AnswerService.saveSurveyAnswer(answer)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveAnswerOption (questionExtended: SurveyAnswerOptionExtendedModel): Promise<void> {
    await AnswerService.saveSurveyAnswerExtended(questionExtended)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveQuestion (question: SurveyQuestionModel): Promise<void> {
    await SurveyQuestionService.saveQuestion(question)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveQuestionExtended (questionExtended: SurveyQuestionExtendedModel): Promise<void> {
    await SurveyQuestionService.saveQuestionExtended(questionExtended)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteQuestion (questionId: number): Promise<void> {
    await SurveyQuestionService.deleteQuestion(questionId)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteAnswer (answerId: number): Promise<void> {
    await AnswerService.deleteSurveyAnswer(answerId)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveComponent (component: SurveyComponentModel): Promise<number> {
    const response = await SurveyComponentService.saveComponent(component)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
    return response
  }

  @Action
  async saveComponentExtended (componentExtended: SurveyComponentExtendedModel): Promise<void> {
    await SurveyComponentService.saveComponentExtended(componentExtended)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveComponentExtendeds (componentExtendeds: Array<SurveyComponentExtendedModel>): Promise<void> {
    await SurveyComponentService.saveComponentExtendeds(componentExtendeds)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteComponent (componentId:number): Promise<void> {
    await SurveyComponentService.deleteComponentById(componentId).then((response) => {
      return true
    }).catch((error) => {
      throw error
    })
  }

  @Action
  async addSurveyComponents (model:CreateSurveyComponentModel):Promise<void> {
    await SurveyComponentService.addSurveyComponents(model)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveSurvey (model:EditSurveyModel):Promise<void> {
    await SurveyService.saveSurvey(model)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async copySurveyComponent (componentId: number):Promise<void> {
    await SurveyComponentService.copySurveyComponent(componentId)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveComponentRule (componentRule: SurveyComponentRuleModel) {
    await SurveyComponentService.saveComponentRule(componentRule)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteComponentRule (surveyComponentRuleId: number): Promise<void> {
    await SurveyComponentService.deleteComponentRule(surveyComponentRuleId).then((response) => {
      return true
    }).catch((error) => {
      throw error
    })
  }

  @Action
  storeSurveyComponentRuleSelectedComponentQuestionId (componentQuestionId: number) {
    this.setSurveyComponentRuleSelectedComponentQuestionId(componentQuestionId)
  }

  @Action
  updateSelected (selected:SurveyComponentDimensionQuestionUIModel) {
    this.setSelected(selected)
  }

  @Action
  async saveSurveyClassification (surveyClassification: SurveyClassificationModel): Promise<void> {
    await SurveyService.saveSurveyClassification(surveyClassification)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getSurveyClassifications (surveyId: number): Promise<void> {
    await SurveyService.getSurveyClassifications(surveyId)
      .then((resp) => {
        this.setCustomClassifications(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getSurveyClassificationElements (surveyClassificationId: number): Promise<Array<SurveyClassificationElementModel>> {
    return await SurveyService.getSurveyClassificationElements(surveyClassificationId)
  }
}
