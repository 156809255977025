import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import DomainModel from '~/shared/model/DomainModel'
import { MainStore } from '~/store/index'
import { DomainService } from '~/shared/service/domain.service'

@Module({ namespaced: true, name: 'DomainModule', dynamic: true, store: MainStore })
export default class DomainModule extends VuexModule {
    domains: Array<DomainModel> = [] as Array<DomainModel>

    @Mutation
    addDomains (domains:Array<DomainModel>) {
      this.domains = domains
    }

    @Action
    async getDomains (): Promise<void> {
      await DomainService.getDomains()
        .then((resp) => {
          this.addDomains(resp)
        })
        .catch((error) => {
          throw error
        })
    }
}
