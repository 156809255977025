import SurveyComponentModel from './SurveyComponentModel'
import SurveyExtendedModel from './SurveyExtendedModel'

export default class EditSurveyModel {
  surveyId :number
  statusId :number
  designator :string
  surveyTitle :string
  researchQuestion :string
  contextId :number
  contextName:string
  createdBy :number
  surveyOwner :number
  introduction :string
  explanatoryText: string

  surveyComponents :Array<SurveyComponentModel>
  surveyExtendeds :Array<SurveyExtendedModel>
}
