import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ChangePasswordModel } from '~/shared/model/ChangePasswordModel'
import { ErrorMessageModel } from '~/shared/model/ErrorMessageModel'
import { ErrorModel } from '~/shared/model/ErrorModel'
import FunctionModel from '~/shared/model/FunctionModel'
import LoginInformation from '~/shared/model/LoginInformation'
import { UserProfileModel } from '~/shared/model/UserProfileModel'
import { AuthenticationService } from '~/shared/service/authentication.service'
import { MainStore } from '~/store/index'

@Module({ namespaced: true, name: 'LoginModule', dynamic: true, store: MainStore })
export default class LoginModule extends VuexModule {
  token: string = localStorage.getItem('token') || ''
  user: UserProfileModel = {} as UserProfileModel
  selectedPage: string = 'Search survey components'
  error:ErrorModel = {} as ErrorModel
  applicationFunctions:Array<string> = [] as Array<string>

  get getApplicationFunctions () {
    return this.applicationFunctions
  }

  get getUserInformation () {
    return this.user
  }

  @Mutation
  addUserInformation (userInformation: UserProfileModel) {
    this.token = userInformation.token
    this.user = new UserProfileModel({
      firstname: userInformation.firstname,
      surname: userInformation.surname,
      email: userInformation.email,
      token: userInformation.token,
      username: userInformation.username,
      forceChange: userInformation.forceChange,
      id: userInformation.id,
      admin: userInformation.admin
    })
  }

  @Mutation
  setForceToFalse () {
    const copyUser = this.user
    this.user = new UserProfileModel({
      firstname: copyUser.firstname,
      surname: copyUser.surname,
      email: copyUser.email,
      token: copyUser.token,
      username: copyUser.username,
      forceChange: false,
      id: copyUser.id,
      admin: copyUser.admin
    })
  }

  @Mutation
  addError (error:ErrorModel) {
    this.error = error
  }

  @Mutation
  resetErrorState () {
    this.error = {} as ErrorModel
  }

  @Mutation
  addFunctions (functions:FunctionModel) {
    this.applicationFunctions = functions.applicationFunctions
  }

  @Mutation
  setSelectedPage (pageTitle: string) {
    this.selectedPage = pageTitle
  }

  @Mutation
  removeToken () {
    this.token = ''
    this.user = {} as UserProfileModel
  }

  @Action
  async login (logininformation: LoginInformation): Promise<boolean> {
    let error = false
    await AuthenticationService.login(logininformation)
      .then((resp) => {
        this.addUserInformation(resp)
        localStorage.setItem('token', resp.token)
        return (error = false)
      })
      .catch((error) => {
        localStorage.removeItem('token')
        throw error
      })
    return error
  }

  @Action
  logout () {
    this.removeToken()
    localStorage.removeItem('token')
  }

  @Action
  async getUserInfo (): Promise<boolean> {
    let error = false
    await AuthenticationService.GetUserInformation()
      .then((resp) => {
        return (error = false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem('token')
        }
      })
    return error
  }

  @Action
  async getAllowedFunctions (): Promise<boolean> {
    let error = false
    await AuthenticationService.GetApplicationFunctions()
      .then((resp) => {
        this.addFunctions(resp)
        return (error = false)
      })
      .catch((error) => {
        throw error
      })
    return error
  }

  @Action
  async changePassword (input: ChangePasswordModel): Promise<boolean> {
    let error = false
    const request = new ChangePasswordModel()
    request.newPassword = input.newPassword
    request.newPasswordSecond = input.newPasswordSecond
    request.username = this.user.username
    await AuthenticationService.ChangePassword(request)
      .then((resp) => {
        this.setForceToFalse()
        return (error = false)
      })
      .catch((error) => {
        throw error
      })
    return error
  }

  @Action
  async forgotPassword (email: string): Promise<boolean> {
    let error = false
    await AuthenticationService.ForgotPassword(email)
      .then((resp) => {
        return (error = false)
      })
      .catch((error) => {
        throw error
      })
    return error
  }
}
