import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import RoleFunctionInRoleModel from '~/shared/model/RoleFunctionInRoleModel'
import RoleFunctionModel from '~/shared/model/RoleFunctionModel'
import { MainStore } from '~/store/index'
import { RoleFunctionService } from '~/shared/service/rolefunction.service'

@Module({ namespaced: true, name: 'RoleFunctionModule', dynamic: true, store: MainStore })

export default class RoleFunctionModule extends VuexModule {
    rolefunctionsInApplicationRole: Array<RoleFunctionInRoleModel> = [] as Array<RoleFunctionInRoleModel>
    rolefunctionsInSurveyRole: Array<RoleFunctionInRoleModel> = [] as Array<RoleFunctionInRoleModel>
    rolefunctionsInStudyRole: Array<RoleFunctionInRoleModel> = [] as Array<RoleFunctionInRoleModel>

    @Mutation
    addrolefunctionsInApplicationRole (rolefunctions:Array<RoleFunctionInRoleModel>) {
      this.rolefunctionsInApplicationRole = rolefunctions
    }

    @Mutation
    addrolefunctionsInSurveyRole (rolefunctions:Array<RoleFunctionInRoleModel>) {
      this.rolefunctionsInSurveyRole = rolefunctions
    }

    @Mutation
    addrolefunctionsInStudyRole (rolefunctions:Array<RoleFunctionInRoleModel>) {
      this.rolefunctionsInStudyRole = rolefunctions
    }

    @Action
    async getAllFunctionsInApplicationRole (roleId:number): Promise<void> {
      await RoleFunctionService.getAllFunctionsInRole(roleId)
        .then((resp) => {
          this.addrolefunctionsInApplicationRole(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async getAllFunctionsSurveyRole (roleId:number): Promise<void> {
      await RoleFunctionService.getAllFunctionsInRole(roleId)
        .then((resp) => {
          this.addrolefunctionsInSurveyRole(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async getAllFunctionsStudyRole (roleId:number): Promise<void> {
      await RoleFunctionService.getAllFunctionsInRole(roleId)
        .then((resp) => {
          this.addrolefunctionsInStudyRole(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async addRoleFunction (roleFunction: RoleFunctionModel): Promise<void> {
      await RoleFunctionService.AddRoleFunction(roleFunction)
        .then()
        .catch((error) => {
          throw error
        })
    }

    @Action
    async deleteRoleFunction (roleFunction: RoleFunctionModel): Promise<void> {
      await RoleFunctionService.DeleteRoleFunction(roleFunction)
        .then()
        .catch((error) => {
          throw error
        })
    }
}
