import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import _ from 'lodash'
import { thisExpression } from '@babel/types'
import ComponentExtendedModel from '~/shared/model/ComponentExtendedModel'
import ComponentModel from '~/shared/model/ComponentModel'
import { MainStore } from '~/store/index'
import { SearchGridModel } from '~/shared/model/SearchGridModel'
import { ComponentService } from '~/shared/service/component.service'
import { DimensionService } from '~/shared/service/dimension.service'
import { QuestionService } from '~/shared/service/question.service'
import ComponentRequestInformation from '~/shared/model/ComponentRequestInformation'
import { SearchGridDetailsModel } from '~/shared/model/SearchGridDetailsModel'
import { SurveyService } from '~/shared/service/survey.service'
import { CreateConceptSurveyRequestModel } from '~/shared/model/CreateConceptSurveyRequestModel'
import TreeViewItem from '~/shared/model/ui-specific/TreeViewItem'
import { TreeNodeType } from '~/shared/enum/TreeNodeType'
import ComponentDimensionModel from '~/shared/model/ComponentDimensionModel'
import ComponentDimensionExtendedModel from '~/shared/model/ComponentDimensionExtendedModel'
import ComponentQuestionExtendedModel from '~/shared/model/ComponentQuestionExtendedModel'
import ComponentQuestionModel from '~/shared/model/ComponentQuestionModel'
import ComponentDimensionQuestionUIModel from '~/shared/model/ui-specific/ComponentDimensionQuestionUIModel'
import ContextModel from '~/shared/model/ContextModel'
import CdpTypeValueModel from '~/shared/model/CdpTypeValueModel'
import { PropertyValueTypeModel } from '~/shared/model/PropertyValueTypeModel'
import { PropertyService } from '~/shared/service/property.service'
import LanguageValidationModel from '~/shared/model/LanguageValidationModel'
import CheckIfComponentHasContextRequestModel from '~/shared/model/CheckIfComponentHasContextRequestModel'
import { LanguageTabModel } from '~/shared/model/LangaugeTabModel'
import ComponentExportModel from '~/shared/model/ComponentExportModel'
import { EditSurveySearchGridModel } from '~/shared/model/EditSurveySearchGridModel'
import UiStateModel from '~/shared/model/ui-specific/UiStateModel'
import ComponentAnswerModel from '~/shared/model/ComponentAnswerModel'
import { AnswerService } from '~/shared/service/answer.service'
import ClassificationModel from '~/shared/model/ClassificationModel'
import ScaleSummaryModel from '~/shared/model/ScaleSummaryModel'
import componentAnswerOptionExtended from '~/shared/model/ComponentAnswerOptionExtendedModel'
import ComponentRuleModel from '~/shared/model/ComponentRuleModel'
import { ClassificationService } from '~/shared/service/classification.service'
import { CreateComponentLanguageModel } from '~/shared/model/SearchComponent/CreateComponentLanguageModel'
import ComponentTranslation from '~/shared/model/ComponentTranslation'
import { ComponentHistoryModel } from '~/shared/model/ComponentHistoryModel'

@Module({ namespaced: true, name: 'ComponentModule', dynamic: true, store: MainStore })
export default class ComponentModule extends VuexModule {
  selectedContextId:number = 1
  componentWithDimensionsAndQuestions: ComponentModel = {} as ComponentModel
  componentExtendeds: Array<ComponentExtendedModel> = [] as Array<ComponentExtendedModel>
  searchGrid: Array<SearchGridModel> = [] as Array<SearchGridModel>
  editSurveySearchGrid: Array<EditSurveySearchGridModel> = [] as Array<EditSurveySearchGridModel>
  searchGridDetails: Array<SearchGridDetailsModel> = [] as Array<SearchGridDetailsModel>
  selectedComponents: Array<SearchGridModel> = [] as Array<SearchGridModel>
  editSurveySelectedComponents: Array<EditSurveySearchGridModel> = [] as Array<EditSurveySearchGridModel>
  selected:ComponentDimensionQuestionUIModel = {} as ComponentDimensionQuestionUIModel
  selectedType:TreeNodeType = {} as TreeNodeType
  contextsList:Array<ContextModel> = [] as Array<ContextModel>
  cdpAnswerTypes:Array<CdpTypeValueModel>= [] as Array<CdpTypeValueModel>
  classifications:Array<ClassificationModel>= [] as Array<ClassificationModel>
  scales : Array<ScaleSummaryModel> = [] as Array<ScaleSummaryModel>
  cdpComponentTypes:Array<CdpTypeValueModel>= [] as Array<CdpTypeValueModel>
  propertyValueTypes: Array<PropertyValueTypeModel> = [] as Array<PropertyValueTypeModel>
  componentCheck: LanguageValidationModel = {} as LanguageValidationModel
  createdSurveyIds:number[] = [] as Array<number>
  languages:Array<LanguageTabModel> = []as Array<LanguageTabModel>
  allLanguages:Array<LanguageTabModel> = [] as Array<LanguageTabModel>
  checkBoxesMode: string
  currentMode:string = 'none'
  pathToComponentExportDocument: string = ''
  uiState: UiStateModel = new UiStateModel()
  componentRuleSelectedComponentQuestionId: number|null = null
  surveyLanguages:Array<LanguageTabModel> = [] as Array<LanguageTabModel>
  componentHistory: Array<ComponentHistoryModel> = [] as Array<ComponentHistoryModel>
  selectedComponentId: number = 0
  selectedComponentDesignator: string = ''
  questionTypes:Array<CdpTypeValueModel>= [] as Array<CdpTypeValueModel>
  matrixTypes:Array<CdpTypeValueModel>= [] as Array<CdpTypeValueModel>
  matrixAnswerTypes:Array<CdpTypeValueModel>= [] as Array<CdpTypeValueModel>
  matrixFormatTypes:Array<CdpTypeValueModel>= [] as Array<CdpTypeValueModel>

  get getCurrentMode () {
    return this.currentMode
  }

  get getcheckBoxesMode () {
    return this.checkBoxesMode
  }

  get getPathToComponentExportDocument () {
    return this.pathToComponentExportDocument
  }

  get getAllContexts (): Array<ContextModel> {
    return this.contextsList
  }

  get getComponentRuleSelectedComponentQuestionId () {
    return this.componentRuleSelectedComponentQuestionId
  }

  @Mutation
  SetCurrentMode (mode:string) {
    this.currentMode = mode
  }

  @Mutation
  SetCheckBoxesMode (mode:string) {
    this.checkBoxesMode = mode
  }

  @Mutation
  RemoveAllSelectedComponents () {
    this.selectedComponents = []
  }

  @Mutation
  RemoveAllSelectedSurveyComponents () {
    this.editSurveySelectedComponents = []
  }

  @Mutation
  AddLanguageToAllLanguages (language:LanguageTabModel) {
    if (this.allLanguages.filter(p => p.value === language.value).length < 1) {
      this.allLanguages.push(language)
    }
  }

  @Mutation
  AddLanguageToSurveyLanguages (language:LanguageTabModel) {
    if (this.surveyLanguages.filter(p => p.value === language.value).length < 1) {
      this.surveyLanguages.push(language)
    }
  }

  @Mutation
  RemoveLanguageFromAllLanguages (language: LanguageTabModel) {
    const inAll = this.allLanguages.filter(p => p.id === language.id)[0]
    const index = this.allLanguages.indexOf(inAll)
    this.allLanguages.splice(index, 1)
  }

  @Mutation
  RemoveLanguageFromSurveyLanguages (language: LanguageTabModel) {
    const inSurveyLanguages = this.surveyLanguages.filter(p => p.id === language.id)[0]
    const index = this.surveyLanguages.indexOf(inSurveyLanguages)
    this.surveyLanguages.splice(index, 1)
  }

  @Mutation
  AddLanguageForCreateSurveyTab (language:LanguageTabModel) {
    this.languages.push(language)
  }

  @Mutation
  RemoveLanguageForCreateSurveyTab (language:LanguageTabModel) {
    const index = this.languages.indexOf(language)
    this.languages.splice(index, 1)
  }

  @Mutation
  ClearLanguageForCreateSurveyTab () {
    this.languages.forEach((language) => {
      if (this.allLanguages.filter(p => p.value === language.value).length < 1) { this.allLanguages.push(language) }
    })
    this.languages = []
  }

  @Mutation
  setPropertyValueTypes (propertyTypes: Array<PropertyValueTypeModel>) {
    this.propertyValueTypes = propertyTypes
  }

  @Mutation
  setCdpComponentTypes (cdpTypeValues:Array<CdpTypeValueModel>) {
    this.cdpComponentTypes = cdpTypeValues
  }

  @Mutation
  setCdpQuestionTypes (cdpTypeValues:Array<CdpTypeValueModel>) {
    this.cdpAnswerTypes = cdpTypeValues
    this.questionTypes = cdpTypeValues
  }

  @Mutation
  setMatrixTypes (cdpTypeValues:Array<CdpTypeValueModel>) {
    this.matrixTypes = cdpTypeValues
  }

  @Mutation
  setMatrixFormatTypes (cdpTypeValues:Array<CdpTypeValueModel>) {
    this.matrixFormatTypes = cdpTypeValues
  }

  @Mutation
  setMatrixAnswerTypes (cdpTypeValues:Array<CdpTypeValueModel>) {
    this.matrixAnswerTypes = cdpTypeValues
  }

  @Mutation
  setClassifications (classifications:Array<ClassificationModel>) {
    this.classifications = classifications
  }

  @Mutation
  setScales (scales:Array<ScaleSummaryModel>) {
    this.scales = scales
  }

  @Mutation
  setSelectedContext (context:ContextModel) {
    this.selectedContextId = context.contextId
  }

  @Mutation
  setContexts (contexts:Array<ContextModel>) {
    this.contextsList = contexts
  }

  @Mutation
  setSelectedType (type:TreeNodeType) {
    this.selectedType = type
  }

  @Mutation
  setSelected (selected:ComponentDimensionQuestionUIModel) {
    this.selected = selected
  }

  @Mutation
  fillComponentGrid (components:Array<SearchGridModel>) {
    this.searchGrid = components
  }

  @Mutation
  fillEditSurveyComponentGrid (components:Array<EditSurveySearchGridModel>) {
    this.editSurveySearchGrid = components
  }

  @Mutation
  setPathToComponentExportDocument (pathToFile: string) {
    this.pathToComponentExportDocument = pathToFile
  }

  @Mutation
  AddComponentToSelectedComponents (componentIds:Array<number>) {
    componentIds.forEach((component) => {
      const searchGridComponent = this.searchGrid.filter(p => p.id === component)
      if (this.selectedComponents.filter(p => p.id === searchGridComponent[0].id).length > 0) {
        return
      }
      this.selectedComponents.push(new SearchGridModel({
        id: component,
        author: searchGridComponent[0].author,
        componentType: searchGridComponent[0].componentType,
        designator: searchGridComponent[0].designator,
        keywords: searchGridComponent[0].keywords
      }))
    })
  }

  @Mutation
  AddSurveyComponentToSelectedSurveyComponents (componentIds:Array<number>) {
    componentIds.forEach((component) => {
      const searchGridComponent = this.editSurveySearchGrid.filter(p => p.id === component)
      if (this.editSurveySelectedComponents.filter(p => p.id === searchGridComponent[0].id).length > 0) {
        return
      }
      this.editSurveySelectedComponents.push(new EditSurveySearchGridModel({
        id: component,
        author: searchGridComponent[0].author,
        componentType: searchGridComponent[0].componentType,
        designator: searchGridComponent[0].designator,
        keywords: searchGridComponent[0].keywords
      }))
    })
  }

  @Mutation
  RemoveComponentToSelectedComponents (componentId:number) {
    // const searchGridComponent = this.searchGrid.filter(p => p.id === componentId)
    // if (searchGridComponent.length > 0) {
    const indexOfComponent = this.selectedComponents.indexOf(this.selectedComponents.filter(p => p.id === componentId)[0])
    this.selectedComponents.splice(indexOfComponent, 1)
    // } else {
    // console.log('component niet gevonden')
    // }
  }

  @Mutation
  RemoveSurveyComponentToSelectedSurveyComponents (componentId:number) {
    const indexOfComponent = this.editSurveySelectedComponents
      .indexOf(this.editSurveySelectedComponents
        .filter(p => p.id === componentId)[0])

    this.editSurveySelectedComponents.splice(indexOfComponent, 1)
  }

  @Mutation
  updateSelectedComponents (componentId:Array<number>) {
    this.selectedComponents = []
    componentId.forEach((id) => {
      this.selectedComponents.push(this.searchGrid.filter(p => p.id === id)[0])
    })
  }

  @Mutation
  fillSearchGridDetails (componentsExtended:Array<SearchGridDetailsModel>) {
    this.searchGridDetails = componentsExtended
  }

  @Mutation
  addComponentWithDimensionsAndQuestions (component:ComponentModel) {
    this.componentWithDimensionsAndQuestions = component
  }

  @Mutation
  addExtendedComponents (componentExtendeds:Array<ComponentExtendedModel>) {
    this.componentExtendeds = componentExtendeds
  }

  @Mutation
  addComponentCheckResults (input:LanguageValidationModel): void {
    this.componentCheck = input
  }

  @Mutation
  addCreatedSurveyId (ids:number[]): void {
    this.createdSurveyIds = []
    ids.forEach((id) => {
      this.createdSurveyIds.push(id)
    })
  }

  @Mutation
  setComponentRuleSelectedComponentQuestionId (componentQuestionId: number) {
    this.componentRuleSelectedComponentQuestionId = componentQuestionId
  }

  @Mutation
  updateSurveyLanguages (contextIds: Array<number>) {
    this.surveyLanguages = []
    this.allLanguages.forEach((language) => {
      if (contextIds.includes(language.id)) {
        this.surveyLanguages.push(language)
      }
    })
    const otherLanguage = new LanguageTabModel({
      id: 0,
      value: 'Other Language',
      short: 'OTH'
    })
    this.surveyLanguages.push(otherLanguage)
  }

  @Mutation
  updateComponentHistoryWithSelectedComponentDetails (componentHistoryModel: ComponentHistoryModel) {
    const componentHistoryIds = this.componentHistory.map(ch => ch.componentId)
    if (componentHistoryIds.includes(componentHistoryModel.componentId)) {
      const index = componentHistoryIds.indexOf(componentHistoryModel.componentId)
      this.componentHistory.splice(index, 1)
    }
    this.componentHistory.unshift(componentHistoryModel)
  }

  @Mutation
  updateSelectedComponentId (componentId: number) {
    this.selectedComponentId = componentId
  }

  @Mutation
  updateSelectedComponentDesignator (designator: string) {
    this.selectedComponentDesignator = designator
  }

  @Action
  async GetMatrixTypesAsync ():Promise<void> {
    const response = await QuestionService.getMatrixTypes()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setMatrixTypes(response)
  }

  @Action
  async GetMatrixFormatTypes ():Promise<void> {
    const response = await QuestionService.getMatrixFormatTypes()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setMatrixFormatTypes(response)
  }

  @Action
  async GetMatrixAnswerTypes ():Promise<void> {
    const response = await QuestionService.getMatrixAnswerTypes()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setMatrixAnswerTypes(response)
  }

  @Action
  async getQuestionTypesAsync ():Promise<void> {
    const response = await QuestionService.getQuestionTypesAsync()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setCdpQuestionTypes(response)
  }

  @Action
  async getAnswerTypesAsync ():Promise<CdpTypeValueModel> {
    const response = await AnswerService.getAnswerTypesAsync()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setCdpQuestionTypes(response)
    return response
  }

  @Action
  async getClassificationsAsync ():Promise<ClassificationModel> {
    const response = await ClassificationService.GetAllClassificationsForAnswer(this.selectedContextId)
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setClassifications(response)
    return response
  }

  @Action
  async getScalesAsync (componentId:number, contextId:number):Promise<ScaleSummaryModel> {
    const response = await AnswerService.getScaleOptionsAsync(componentId, contextId)
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setScales(response)
    return response
  }

  @Action
  async getEditComponentPageTitle (componentId: number): Promise<string> {
    const response = await ComponentService.getEditComponentPageTitle(componentId)
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    return response
  }

  @Action
  async getAllExtendedComponentsByComponentId (componentRequestInformation: ComponentRequestInformation): Promise<void> {
    await ComponentService.getAllExtendedComponentsByComponentId(
      componentRequestInformation.componentId, componentRequestInformation.contextId)
      .then((resp) => {
        this.addExtendedComponents(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getAllSearchGridComponents (): Promise<void> {
    await ComponentService.getAllSearchGridComponents()
      .then((resp) => {
        this.fillComponentGrid(resp)
      })
      .catch((error) => {
        if (error.errorCode === 401) {
          // localStorage.removeItem('token')
        } else {
          console.log('iets anders')
        }
        throw error
      })
  }

  @Action
  async getAllSearchGridComponentsByContextId (contextId:number): Promise<void> {
    await ComponentService.getAllSearchGridComponentsByContextId(contextId)
      .then((resp) => {
        this.fillEditSurveyComponentGrid(resp)
      })
      .catch((error) => {
        if (error.errorCode === 401) {
          // localStorage.removeItem('token')
        } else {
          console.log('iets anders')
        }
        throw error
      })
  }

  @Action
  async getComponentDetailsById (id:number): Promise<void> {
    await ComponentService.getComponentDetailsByComponentId(id)
      .then((resp) => {
        this.fillSearchGridDetails(resp)
      }).catch((error) => {
        throw error
      })
  }

  @Action
  async getComponentWithDimensionsAndQuestions (payload:{componentId: number, contextId: number}): Promise<void> {
    await ComponentService.GetQuestionsAndDimensionByComponentId(payload.componentId, payload.contextId)
      .then((resp) => {
        this.addComponentWithDimensionsAndQuestions(resp)
      })
      .catch((error) => {
        throw error
      })
  }

  @Action
  // eslint-disable-next-line max-len
  async getComponentExportDocument (payload:{componentId: number, metadata: boolean, contextId: number}): Promise<ComponentExportModel> {
    let componentExportModel: ComponentExportModel = new ComponentExportModel()
    await ComponentService.getComponentExportDocument(payload.componentId, payload.metadata, payload.contextId)
      .then((resp) => {
        componentExportModel = resp
        this.setPathToComponentExportDocument(componentExportModel.pathToFile)
      })
      .catch((error) => {
        throw error
      })
    return componentExportModel
  }

  @Action
  getComponentTreeView (): Array<TreeViewItem> {
    const treeViewItems: Array<TreeViewItem> = new Array<TreeViewItem>(0)
    let componentTreeItemsTemp:Array<TreeViewItem> = new Array<TreeViewItem>(0)
    const component = this.componentWithDimensionsAndQuestions
    if (this.componentWithDimensionsAndQuestions) {
      const componentTreeItem:TreeViewItem = {
        id: `${component.componentId}`,
        parentId: null,
        expanded: true,
        selected: false,
        class: 'component',
        name: component.designator,
        treeNodeType: TreeNodeType.Component,
        items: new Array<TreeViewItem>(0),
        position: 1
      }
      if (component.componentDimensions && component.componentDimensions.length > 0) {
        // Add dimenstion items to compont treeview item
        component.componentDimensions.forEach((dimension:ComponentDimensionModel) => {
          const dimensionItem: TreeViewItem = {
            id: `${component.componentId}_dimension_${dimension.componentDimensionId}`,
            parentId: `${component.componentId}`,
            class: 'dimension',
            name: `${dimension.designator}`,
            treeNodeType: TreeNodeType.Dimension,
            expanded: false,
            selected: false,
            items: new Array<TreeViewItem>(0),
            position: dimension.position
          }
          // Add question to dimension treeview item
          if (dimension.componentQuestions) {
            const questions = dimension.componentQuestions
            questions.forEach((question) => {
              const questionItem:TreeViewItem = {
                id: `${component.componentId}_dimension_${dimension.componentDimensionId}_question_${question.componentQuestionId}`,
                parentId: `${dimensionItem.id}`,
                class: 'question',
                name: question.name,
                treeNodeType: TreeNodeType.Question,
                expanded: false,
                selected: false,
                items: new Array<TreeViewItem>(0),
                position: question.position
              }
              question.componentAnswerOptions?.forEach((comAnswer) => {
                const answerItem:TreeViewItem = {
                  // eslint-disable-next-line max-len
                  id: `${component.componentId}_dimension_${dimension.componentDimensionId}_question_${question.componentQuestionId}_answerOption_${comAnswer.componentAnswerOptionId}`,
                  parentId: questionItem.id,
                  class: 'answerOption',
                  name: comAnswer.name,
                  treeNodeType: TreeNodeType.Answer,
                  expanded: false,
                  selected: false,
                  items: null,
                  position: comAnswer.position
                }
                questionItem.items?.push(answerItem)
                questionItem.items = _.orderBy(questionItem.items, ['position'], ['asc'])
              })
              dimensionItem.items?.push(questionItem)
              dimensionItem.items = _.orderBy(dimensionItem.items, ['position'], ['asc'])
            })
          }
          componentTreeItemsTemp.push(dimensionItem)
        })
      }
      const componentQuestions = component.componentQuestions?.filter((q) => {
        return !q.componentDimensionId
      })

      // Add root level component question
      if (componentQuestions) {
        componentQuestions.forEach((comQuestion) => {
          const questionItem:TreeViewItem = {
            id: `${component.componentId}_question_${comQuestion.componentQuestionId}`,
            parentId: componentTreeItem.id,
            class: 'question',
            name: comQuestion.name,
            treeNodeType: TreeNodeType.Question,
            expanded: false,
            selected: false,
            items: new Array<TreeViewItem>(0),
            position: comQuestion.position
          }

          comQuestion.componentAnswerOptions?.forEach((comAnswer) => {
            const answerItem:TreeViewItem = {
              // eslint-disable-next-line max-len
              id: `${component.componentId}_question_${comQuestion.componentQuestionId}_answerOption_${comAnswer.componentAnswerOptionId}`,
              parentId: questionItem.id,
              class: 'answerOption',
              name: comAnswer.name,
              treeNodeType: TreeNodeType.Answer,
              expanded: false,
              selected: false,
              items: null,
              position: comAnswer.position
            }
            questionItem.items?.push(answerItem)
            questionItem.items = _.orderBy(questionItem.items, ['position'], ['asc'])
          })

          componentTreeItemsTemp.push(questionItem)
        })
      }
      // Order by position
      componentTreeItemsTemp = _.orderBy(componentTreeItemsTemp, ['position'], ['asc'])
      // add titem to component
      componentTreeItemsTemp.forEach((i) => {
        componentTreeItem.items?.push(i)
      })
      treeViewItems.push(componentTreeItem)
    }

    return treeViewItems
  }

  @Action
  async saveDimension (dimension: ComponentDimensionModel): Promise<number> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }
    dimension.contextId = this.selectedContextId

    const response = await DimensionService.saveDimension(dimension)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
    return response
  }

  @Action
  async saveDimensionExtended (dimensionExtended: ComponentDimensionExtendedModel): Promise<void> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }
    dimensionExtended.contextId = this.selectedContextId

    await DimensionService.saveDimensionExtended(dimensionExtended)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteDimension (dimensionId: number): Promise<void> {
    await DimensionService.deleteDimension(dimensionId)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveQuestion (question: ComponentQuestionModel): Promise<void> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }
    question.contextId = this.selectedContextId

    await QuestionService.saveQuestion(question)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveAnswer (answer: ComponentAnswerModel): Promise<void> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }
    answer.contextId = this.selectedContextId
    await AnswerService.saveAnswer(answer)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveCopyScale (scaleSummary: ScaleSummaryModel): Promise<void> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }
    scaleSummary.contextId = this.selectedContextId
    await AnswerService.saveCopyScale(scaleSummary)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveQuestionExtended (questionExtended: ComponentQuestionExtendedModel): Promise<void> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }
    questionExtended.contextId = this.selectedContextId

    await QuestionService.saveQuestionExtended(questionExtended)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveAnswerOption (questionExtended: componentAnswerOptionExtended): Promise<void> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }

    questionExtended.contextId = this.selectedContextId
    await AnswerService.saveAnswerExtended(questionExtended)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteQuestion (questionId: number): Promise<void> {
    await QuestionService.deleteQuestion(questionId)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteAnswer (answerId: number): Promise<void> {
    await AnswerService.deleteAnswer(answerId)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getContexts (): Promise<void> {
    await ComponentService.getContexts()
      .then((resp) => {
        this.setContexts(resp)
      }).catch((error) => {
        throw error
      })
  }

  @Action
  async saveComponent (component: ComponentModel): Promise<number> {
    if (component.contextId === undefined || component.contextId === null) {
      if (!this.selectedContextId || this.selectedContextId === 0) {
        throw new Error('ContextId is required')
      }
      component.contextId = this.selectedContextId
    }
    const response = await ComponentService.saveComponent(component)
      .then((res) => {
        return res
      })
      .catch((error) => {
        throw error
      })
    return response
  }

  @Action
  async saveComponentExtended (componentExtended: ComponentExtendedModel): Promise<void> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }
    componentExtended.contextId = this.selectedContextId

    await ComponentService.saveComponentExtended(componentExtended)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async saveComponentExtendeds (componentExtendeds: Array<ComponentExtendedModel>): Promise<void> {
    if (!this.selectedContextId || this.selectedContextId === 0) {
      throw new Error('ContextId is required')
    }

    componentExtendeds.forEach((extended) => { extended.contextId = this.selectedContextId })
    await ComponentService.saveComponentExtendeds(componentExtendeds)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async getComponentTypesAsync ():Promise<CdpTypeValueModel> {
    const response = await ComponentService.getComponentTypesAsync()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        throw error
      })
    this.setCdpComponentTypes(response)
    return response
  }

  // metadata
  @Action
  async getAllPropertyValueTypes () {
    await PropertyService.getAllPropertyValueTypes(this.selectedContextId)
      .then((result: Array<PropertyValueTypeModel>) => {
        this.setPropertyValueTypes(result)
      })
      .catch((error) => {
        throw new Error(`Get all property value types  ${error}`)
      })
  }

  @Action
  async CreateConceptSurvey (request:CreateConceptSurveyRequestModel): Promise<void> {
    await SurveyService.createConceptSurvey(request).then((resp) => {
      this.addCreatedSurveyId(resp)
    })
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteComponent (componentId:number): Promise<void> {
    await ComponentService.deleteComponentById(componentId).then((response) => {
      return true
    }).catch((error) => {
      throw error
    })
  }

  @Action
  async checkIfComponentsHasSelectedContext (request :CheckIfComponentHasContextRequestModel):Promise<void> {
    await ComponentService.checkIfComponentHasContext(request).then((response) => {
      this.addComponentCheckResults(response)
    }).catch((error) => {
      throw error
    })
  }

  @Action
  async saveComponentRule (componentRule: ComponentRuleModel): Promise<void> {
    await ComponentService.saveComponentRule(componentRule)
      .catch((error) => {
        throw error
      })
  }

  @Action
  async deleteComponentRule (componentRuleId:number): Promise<void> {
    await ComponentService.deleteComponentRule(componentRuleId).then((response) => {
      return true
    }).catch((error) => {
      throw error
    })
  }

  @Action
  storeComponentRuleSelectedComponentQuestionId (componentQuestionId: number) {
    this.setComponentRuleSelectedComponentQuestionId(componentQuestionId)
  }

  @Action
  async getContextIdsForSelectedComponents (componentIds: Array<number>): Promise<Array<number>> {
    let contextIds: Array<number> = [] as number[]
    await ComponentService.getContextIdsForSelectedComponents(componentIds)
      .then((res) => {
        contextIds = res
      })
      .catch((error) => {
        throw error
      })
    return contextIds
  }

  @Action
  setSurveyLanguages (contextIds: Array<number>) {
    this.updateSurveyLanguages(contextIds)
  }

  @Action
  updateComponentHistory (componentHistoryModel: ComponentHistoryModel) {
    this.updateComponentHistoryWithSelectedComponentDetails(componentHistoryModel)
  }

  @Action
  setSelectedComponentId (componentId: number) {
    this.updateSelectedComponentId(componentId)
  }

  @Action
  setSelectedComponentDesignator (designator: string) {
    this.updateSelectedComponentDesignator(designator)
  }
}
