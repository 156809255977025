import { ApiUrl } from '../util/api.url'
import SurveyQuestionModel from '../model/SurveyQuestionModel'
import SurveyQuestionExtendedModel from '../model/SurveyQuestionExtendedModel'
import MatrixModel from '../model/Question/MatrixModel'
import SingleChoiceModel from '../model/Question/SingleChoiceModel'
import OpenQuestionModel from '../model/Question/OpenQuestionModel'
import SurveyComponentQuestionCodeValidationRequestModel from '../model/Question/SurveyComponentQuestionCodeValidationRequestModel'
import BipolarModel from '../model/Question/BipolarModel'
import BipolarRequestModel from '../model/Question/BipolarRequestModel'
import { BaseService } from './base.service'

export class SurveyQuestionService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/SurveyQuestions/'

    static async saveQuestion (question:SurveyQuestionModel): Promise<Array<void>> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSurveyQuestion', question)
      return response.data
    }

    static async saveQuestionExtended (questionExtended: SurveyQuestionExtendedModel): Promise<Array<void>> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSurveyQuestionExtended', questionExtended)
      return response.data
    }

    static async deleteQuestion (questionId:number): Promise<Array<void>> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteSurveyQuestion', { questionId: questionId })
      return response.data
    }

    static async getQuestionTypesAsync () {
      const response = await this.getApiCall(this.baseUrl + 'GetQuestionTypes')
      return response.data
    }

    static async getMatrixProperties (questionId:number): Promise<MatrixModel> {
      const param = {
        questionId: questionId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetMatrixProperties', param)
      return response.data
    }

    static async GetOpenProperties (questionId:number): Promise<OpenQuestionModel> {
      const param = {
        questionId: questionId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetOpenProperties', param)
      return response.data
    }

    static async GetSingleChoiceProperties (questionId:number): Promise<SingleChoiceModel> {
      const param = {
        questionId: questionId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetSingleChoiceProperties', param)
      return response.data
    }

    static async SaveMatrixProperties (request:MatrixModel):Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveMatrixProperties', request)
      return response.data
    }

    static async SaveOpenProperties (request:OpenQuestionModel):Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveOpenProperties', request)
      return response.data
    }

    static async SaveSingleChoiceProperties (request:SingleChoiceModel):Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSingleChoiceProperties', request)
      return response.data
    }

    static async ValidateSurveyComponentQuestionCode (request :SurveyComponentQuestionCodeValidationRequestModel): Promise<boolean> {
      const response = await this.postApiCall(this.baseUrl + 'ValidateSurveyComponentQuestionCode', request)
      return response.data
    }

    static async GetBipolarProperties (contextId:number, questionId:number): Promise<Array<BipolarModel>> {
      const param = {
        contextId: contextId,
        questionId: questionId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetBipolarProperties', param)
      return response.data
    }

    static async SaveBipolarProperties (request:BipolarRequestModel):Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveBipolarProperties', request)
      return response.data
    }
}
