import Axios from 'axios'
import { ApiUrl } from '../util/api.url'
import ComponentDimensionModel from '../model/ComponentDimensionModel'
import ComponentDimensionExtendedModel from '../model/ComponentDimensionExtendedModel'
import { BaseService } from './base.service'

export class DimensionService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/Dimensions/'

    static async saveDimension (dimension:ComponentDimensionModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveDimension', dimension)
      return response.data
    }

    static async saveDimensionExtended (dimesnionExtended:ComponentDimensionExtendedModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveDimensionExtended', dimesnionExtended)
      return response.data
    }

    static async deleteDimension (dimensionId:number): Promise<void> {
      const param = {
        dimensionId: dimensionId
      }
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteDimension', param)
      return response.data
    }
}
