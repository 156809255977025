import UserRoleSurveyModel from '@/shared/model/UserRoleSurveyModel';
import UserApplicationRoleModel from '@/shared/model/UserApplicationRoleModel';

export default class UserModel {
    userId: number
    firstname: string
    surname: string
    username: string
    email: string
    domainId: number
    domainName: string
    admin: boolean
    active: boolean
    forceChange: boolean
    externalUser: boolean
    userSurveyRoles: Array<UserRoleSurveyModel> = []
    userApplicationRoles: Array<UserApplicationRoleModel> = []
}