import Axios from 'axios'
import { ApiUrl } from '../util/api.url'
import { BaseService } from './base.service'
import DomainModel from '~/shared/model/DomainModel'

export class DomainService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/Domain/'

    static async getDomains (): Promise<Array<DomainModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetDomains')
      return response.data
    }
}
