





















import { Component, mixins, Prop } from 'nuxt-property-decorator'
import LoaderMixin from '@core/mixins/LoaderMixin'

@Component({
  components: {}
})
export default class HorizontalTemplate extends mixins(LoaderMixin) {
  /* Props */
  @Prop({ required: false, type: Boolean, default: false })
  toggleable: boolean

  /* Watch */

  /* Data */
  showMenu: boolean = true

  /* Enums */

  /* Lifecycle */

  /* Computed */
  get hasMenu () {
    return !!this.$slots.menu
  }

  /* Methods */
  toggleMenu () {
    this.showMenu = !this.showMenu
  }

  /* Events/Listeners */
}
