import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { StudyGridModel } from '~/shared/model/Study/StudyGridModel'
import { studyService } from '~/shared/service/study.service'
import { MainStore } from '~/store/index'
import { StudyMetadataModel } from '~/shared/model/Study/StudyMetadataModel'
import { CreateStudyRequestModel } from '~/shared/model/Study/CreateStudyRequestModel'
import { StudyDetailsModel } from '~/shared/model/Study/StudyDetailsModel'
import { SurveyLinkModel } from '~/shared/model/Study/SurveyLinkModel'
import UserRoleStudyModel from '~/shared/model/Study/UserRoleStudyModel'
import { StudyDatasetModel } from '~/shared/model/Study/StudyDatasetModel'
import { StudyShareModel } from '~/shared/model/Study/StudyShareModel'
import { StudyFunctionModel } from '~/shared/model/Study/StudyFunctionModel'
import { SaveUserFunctionRequest } from '~/shared/model/Study/SaveUserFunctionRequest'
import { UpdateStudyShareModel } from '~/shared/model/Study/UpdateStudyShareModel'
import { StudyLinkModel } from '~/shared/model/Study/StudyLinkModel'
import { StudyHistoryModel } from '~/shared/model/Study/StudyHistoryModel'
import { StudyDetailPopUpModel } from '~/shared/model/Study/StudyDetailPopUpModel'

@Module({ namespaced: true, name: 'StudyModule', dynamic: true, store: MainStore })
export default class StudyModule extends VuexModule {
studies: Array<StudyGridModel> = [] as Array<StudyGridModel>
studyPopUpDetails:Array<StudyDetailPopUpModel>= [] as Array<StudyDetailPopUpModel>
studyDetails:StudyDetailsModel = {} as StudyDetailsModel
selectedStudyId:number = 0
selectedStudyMetadata: Array<StudyMetadataModel> = [] as Array<StudyMetadataModel>
surveyLinks:Array<SurveyLinkModel> = [] as Array<SurveyLinkModel>
availableUsers: Array<UserRoleStudyModel> = [] as Array<UserRoleStudyModel>
dataSets:Array<StudyDatasetModel> = [] as Array<StudyDatasetModel>
externalLinks:Array<StudyLinkModel> = [] as Array<StudyLinkModel>
shared:Array<StudyShareModel> = [] as Array<StudyShareModel>
datasetFunctions:Array<StudyFunctionModel> = [] as Array<StudyFunctionModel>
selectedDatasetFunctions:Array<StudyFunctionModel> = [] as Array<StudyFunctionModel>
selectedDataSetPermissionUserId:number = 0
surveyFunctions:Array<StudyFunctionModel> = [] as Array<StudyFunctionModel>
selectedSurveyFunctions:Array<StudyFunctionModel> = [] as Array<StudyFunctionModel>
selectedSurveyPermissionUserId:number = 0
studyHistory: Array<StudyHistoryModel> = [] as Array<StudyHistoryModel>

get getFromStudy (): boolean {
  if (this.selectedStudyId !== 0) {
    return true
  }
  return false
}

@Mutation
setStudies (studies:Array<StudyGridModel>) {
  this.studies = studies
}

@Mutation
setStudyPopUpDetails (details:Array<StudyDetailPopUpModel>) {
  this.studyPopUpDetails = details
}

@Mutation
setStudyDetails (studyDetails:StudyDetailsModel) {
  this.studyDetails = studyDetails
  this.selectedStudyMetadata = studyDetails.metadataList
  this.surveyLinks = studyDetails.surveyLinks
  this.dataSets = studyDetails.datasets
  this.shared = studyDetails.shared
  this.externalLinks = studyDetails.externalLinks
}

@Mutation
SetSelectedStudyId (studyId:number) {
  this.selectedStudyId = studyId
}

@Mutation
setSelectedStudyMetadata (studyMetadata: Array<StudyMetadataModel>) {
  this.selectedStudyMetadata = studyMetadata
}

@Mutation
setSurveyLinks (surveyLinks: Array<SurveyLinkModel>) {
  this.surveyLinks = surveyLinks
}

@Mutation
setExternalLinks (studyLinks: Array<StudyLinkModel>) {
  this.externalLinks = studyLinks
}

@Mutation
setAvailableUsers (users: Array<UserRoleStudyModel>) {
  this.availableUsers = users
}

@Mutation
setDatasets (datasets: Array<StudyDatasetModel>) {
  this.dataSets = datasets
}

@Mutation
setDatasetFunctions (datasetFunctions: Array<StudyFunctionModel>) {
  this.datasetFunctions = datasetFunctions
}

@Mutation
setSurveyFunctions (surveyFunctions: Array<StudyFunctionModel>) {
  this.surveyFunctions = surveyFunctions
}

@Mutation
setSelectedDataSetFunctions (datasetFunctions: Array<StudyFunctionModel>) {
  this.selectedDatasetFunctions = datasetFunctions
}

@Mutation
setSelectedSurveyFunctions (surveyFunctions: Array<StudyFunctionModel>) {
  this.selectedSurveyFunctions = surveyFunctions
}

@Mutation
setSelectedDataSetPermissionUser (userId: number) {
  this.selectedDataSetPermissionUserId = userId
}

@Mutation
setSelectedSurveyPermissionUser (userId: number) {
  this.selectedSurveyPermissionUserId = userId
}

@Mutation
updateStudyHistoryWithSelectedStudyDetails (studyHistoryModel: StudyHistoryModel) {
  const studyHistoryIds = this.studyHistory.map(sh => sh.studyId)
  if (studyHistoryIds.includes(studyHistoryModel.studyId)) {
    const index = studyHistoryIds.indexOf(studyHistoryModel.studyId)
    this.studyHistory.splice(index, 1)
  }
  this.studyHistory.unshift(studyHistoryModel)
}

@Action
async CreateStudy (request:CreateStudyRequestModel): Promise<number> {
  await studyService.CreateStudy(request)
    .then((resp) => {
      this.SetSelectedStudyId(resp)
      return resp
    })
    .catch((error) => {
      throw error
    })
  return 0
}

@Action
async getAllStudies (): Promise<void> {
  await studyService.GetAllStudies()
    .then((resp) => {
      this.setStudies(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async getStudyPopUpDetails (studyId:number):Promise<void> {
  await studyService.GetStudyPopUpDetailsByStudyId(studyId)
    .then((resp) => {
      this.setStudyPopUpDetails(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async getStudyDetails ():Promise<void> {
  if (this.selectedStudyId === 0) {
    return
  }
  await studyService.GetStudyDetailsByStudyId(this.selectedStudyId)
    .then((resp) => {
      this.setStudyDetails(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async saveStudyMetadata (metadata: StudyMetadataModel): Promise<void> {
  await studyService.SaveStudyMetadata(metadata)
    .catch((error) => {
      throw error
    })
}

@Action
async getStudyMetadata (studyId: number): Promise<void> {
  await studyService.GetStudyMetadata(studyId)
    .then((resp) => {
      this.setSelectedStudyMetadata(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async getSurveyLinks (): Promise<void> {
  if (this.selectedStudyId === 0) {
    return
  }
  await studyService.GetSurveyLinksByStudyId(this.selectedStudyId)
    .then((resp) => {
      this.setSurveyLinks(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async getExternalLinks (): Promise<void> {
  if (this.selectedStudyId === 0) {
    return
  }
  await studyService.GetExternalLinksByStudyId(this.selectedStudyId)
    .then((resp) => {
      this.setExternalLinks(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async getDatasets (): Promise<void> {
  if (this.selectedStudyId === 0) {
    return
  }
  await studyService.GetDatasetsByStudyId(this.selectedStudyId)
    .then((resp) => {
      this.setDatasets(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async getDatasetFunctions (): Promise<void> {
  await studyService.GetDatasetFunctions()
    .then((resp) => {
      this.setDatasetFunctions(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async getSurveyFunctions (): Promise<void> {
  await studyService.GetSurveyFunctions()
    .then((resp) => {
      this.setSurveyFunctions(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async UpdateDatasetFunctions (datasetId:number): Promise<void> {
  const request = new SaveUserFunctionRequest()
  request.userId = this.selectedDataSetPermissionUserId
  request.datasetId = datasetId
  request.Functions = this.selectedDatasetFunctions
  await studyService.SaveUserDatasetPermissions(request)
    .then((resp) => {
      return true
    })
    .catch((error) => {
      throw error
    })
}

@Action
async UpdateSurveyFunctions (surveyId:number): Promise<void> {
  const request = new SaveUserFunctionRequest()
  request.userId = this.selectedSurveyPermissionUserId
  request.datasetId = surveyId
  request.Functions = this.selectedSurveyFunctions
  await studyService.SaveUserSurveyPermissions(request)
    .then((resp) => {
      return true
    })
    .catch((error) => {
      throw error
    })
}

@Action
async deleteStudyById (studyId:number):Promise<void> {
  await studyService.DeleteStudyById(studyId)
}

@Action
async getAvailableUsers (): Promise<void> {
  await studyService.GetActiveUsers()
    .then((resp) => {
      this.setAvailableUsers(resp)
    })
    .catch((error) => {
      throw error
    })
}

@Action
async addUserToStudy (studyShareModel: StudyShareModel): Promise<void> {
  await studyService.AddUserToStudy(studyShareModel)
    .catch((error) => {
      throw error
    })
}

@Action
async deleteUserFromStudy (updateStudyShareModel: UpdateStudyShareModel): Promise<void> {
  await studyService.DeleteUserFromStudy(updateStudyShareModel)
    .catch((error) => {
      throw error
    })
}

@Action
async deleteDataset (datasetId: number): Promise<void> {
  await studyService.DeleteDatasetById(datasetId)
    .catch((error) => {
      throw error
    })
}

@Action
async updateUserRolesForStudy (updateStudyShareModel: UpdateStudyShareModel): Promise<void> {
  await studyService.UpdateUserRolesForStudy(updateStudyShareModel)
    .catch((error) => {
      throw error
    })
}

@Action
async updateStudyDetails (studyDetailsModel: StudyDetailsModel): Promise<void> {
  await studyService.UpdateStudyDetails(studyDetailsModel)
    .catch((error) => {
      throw error
    })
}

@Action
updateStudyHistory (studyHistoryModel: StudyHistoryModel) {
  this.updateStudyHistoryWithSelectedStudyDetails(studyHistoryModel)
}
}
