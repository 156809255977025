export class ApiUrl {
  /**
   * ApiUrl() gives the endpoint for the web api
   */
  public static webapiurl () {
    switch (window.location.host) {
      case 'cdp.wecr.wur.nl':
        return 'https://cdp.wecr.wur.nl/WebApi'
      case 'acc.cdp.wecr.wur.nl':
        return 'https://acc.cdp.wecr.wur.nl/WebApi'
      case 'tst.cdp.wecr.wur.nl':
        return 'https://tst.cdp.wecr.wur.nl/WebApi'
        case 'cdp.wser.wur.nl':
          return 'https://cdp.wser.wur.nl/WebApi'
        case 'acc.cdp.wser.wur.nl':
          return 'https://acc.cdp.wser.wur.nl/WebApi'
        case 'tst.cdp.wser.wur.nl':
          return 'https://tst.cdp.wser.wur.nl/WebApi'
      default:
        return 'http://localhost:55910'
    }
  }
}
