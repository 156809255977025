import Axios from 'axios'
import { ApiUrl } from '../util/api.url'
import SurveyDimensionModel from '../model/SurveyDimensionModel'
import SurveyDimensionExtendedModel from '../model/SurveyDimensionExtendedModel'
import { BaseService } from './base.service'

export class SurveyDimensionService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/SurveyDimensions/'

    static async saveDimension (dimension:SurveyDimensionModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSurveyDimension', dimension)
      return response.data
    }

    static async saveDimensionExtended (dimesnionExtended:SurveyDimensionExtendedModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SavesurveyDimensionExtended', dimesnionExtended)
      return response.data
    }

    static async deleteDimension (dimensionId:number): Promise<void> {
      const param = {
        dimensionId: dimensionId
      }
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteSurveyDimension', param)
      return response.data
    }
}
