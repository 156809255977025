import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ApplicationUserInRoleModel from '../shared/model/ApplicationUserInRoleModel'
import ApplicationRoleUserModel from '../shared/model/ApplicationRoleUserModel'
import { MainStore } from '~/store/index'
import { ApplicationRoleService } from '~/shared/service/applicationrole.service'

@Module({ namespaced: true, name: 'ApplicationRoleModule', dynamic: true, store: MainStore })
export default class ApplicationRoleModule extends VuexModule {
    applicationusersInRole: Array<ApplicationUserInRoleModel> = [] as Array<ApplicationUserInRoleModel>

    @Mutation
    addApplicationUsersInRole(applicationusers:Array<ApplicationUserInRoleModel>){
        this.applicationusersInRole = applicationusers
    }

    @Action
    async getAllApplicationUsersInRole(roleId:number): Promise<void> {
        await ApplicationRoleService.getAllApplicationUsersInRole(roleId)
            .then((resp) => {        
                this.addApplicationUsersInRole(resp)
            })
            .catch((error) => {
                throw error
            })
    }

    @Action
    async addApplicationUser(applicationUser: ApplicationRoleUserModel): Promise<void> {
        await ApplicationRoleService.AddApplicationRoleUser(applicationUser)
            .then()
            .catch((error) => {
                throw error
            })
    }

    @Action
    async deleteApplicationUser(applicationUser: ApplicationRoleUserModel): Promise<void> {
        await ApplicationRoleService.DeleteApplicationRoleUser(applicationUser)
            .then()
            .catch((error) => {
                throw error
            })
    }

}