export default class UiStateModel {
  constructor () {
    this.openPanels = undefined
    this.openTreeItems = undefined
    this.selectedComponentTabs = undefined
    this.selectedQuestionTabs = undefined
    this.selectedSurveyTabs = undefined
  }

  openPanels:number[]|undefined
  selectedComponentTabs:number[]|undefined
  selectedQuestionTabs:number[]|undefined
  selectedDimensionTabs:number[]|undefined
  selectedSurveyTabs:number[]|undefined
  openTreeItems:string[]|undefined
}
