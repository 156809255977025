import Axios from 'axios'
import ApplicationRoleModel from '../model/ApplicationRoleModel'
import { ApiUrl } from '../util/api.url'
import AddRoleRequest from '../model/AddRoleRequest'
import { BaseService } from './base.service'
import RoleModel from '~/shared/model/RoleModel'

export class RoleService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/Role/'

    static async getAllApplicationRoles (): Promise<Array<ApplicationRoleModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetAllApplicationRoles')
      return response.data
    }

    static async AddApplicationRole (roleName: string): Promise<void> {
      const request = new AddRoleRequest()
      request.roleName = roleName
      const response = await this.postApiCall(this.baseUrl + 'AddApplicationRole', request)
        .catch((error) => {
          throw error.message.substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async DeleteRole (roleId: number): Promise<void> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteRole?roleId=' + roleId, null)
        .catch((error) => {
          throw error.message.substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async GetStudyRoles (): Promise<Array<RoleModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetStudyRoles')
      return response.data
    }

    static async AddStudyRole (roleName: string): Promise<void> {
      const request = new AddRoleRequest()
      request.roleName = roleName
      const response = await this.postApiCall(this.baseUrl + 'AddStudyRole', request)
        .catch((error) => {
          throw error.message.substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }
}
