import { IDwhClassificationList, IDwhClassificationElement } from '@fb-it/vue-dwh/src/types/classifications'
import { IDwhContextListItem } from '@fb-it/vue-dwh/src/types/contexts/DwhContextListItem'
import { IDwhListItem } from '@fb-it/vue-dwh/src/types/DwhListItem'
import { IDwhClassification } from '@fb-it/vue-dwh/src/types/classifications/DwhClassification'
import { DwhClassificationListItem } from '../model/classifcationManager/DwhClassificationListItem'
import { ApiUrl } from '../util/api.url'
import { DWHCommonConceptModel } from '../model/classifcationManager/DWHCommonConceptModel'
import { AddcommonConceptRequestModel } from '../model/classifcationManager/AddcommonConceptRequestModel'
import { BaseService } from './base.service'
import ClassificationRequestModel from '~/shared/model/ClassificationRequestModel'
import { SaveClassificationElementsRequestModel } from '~/shared/model/classifcationManager/SaveClassificationElementsRequestModel'
import { ClassificationElementModel } from '~/shared/model/classifcationManager/ClassificationElementModel'

export class ClassificationService extends BaseService {
  private static baseUrl = ApiUrl.webapiurl() + '/Classification/'

  static async GetAllClassificationsForAnswer(contextId: number) {
    const param = {
      contextId: contextId,
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetAllClassificationsForAnswer', param)
    return response.data
  }

  static async GetAllClassifications(contextId: number): Promise<Array<DwhClassificationListItem>> {
    const param = {
      contextId: contextId,
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetAllClassifications', param)
    return response.data
  }

  static async GetAllDWHClassifications(contextId: number): Promise<IDwhClassificationList> {
    const param = {
      contextId: contextId,
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetAllDWHClassifications', param)
    return response.data
  }

  static async GetAllContexts(): Promise<Array<IDwhContextListItem>> {
    const response = await this.getApiCall(this.baseUrl + 'GetAllContextsFromDWH')
    return response.data
  }

  static async GetAllTypes(): Promise<IDwhListItem<number>[]> {
    const response = await this.getApiCall(this.baseUrl + 'GetAllClassificationTypes')
    return response.data
  }

  static async GetClassification(classificationId: number, contextId: number): Promise<IDwhClassification> {
    const param = {
      classificationId: classificationId,
      contextId: contextId,
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetClassification', param)
    return response.data
  }

  static async SaveClassification(classification: IDwhClassification): Promise<number> {
    const response = await this.postApiCall(this.baseUrl + 'SaveClassification', classification)
    return response.data
  }

  static async GetClassificationElements(classificationRequest: ClassificationRequestModel): Promise<Array<IDwhClassificationElement>> {
    const param = {
      classificationId: classificationRequest.classificationId,
      contextId: classificationRequest.contextId,
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetClassificationElements', param)
    if (response.data.some((x) => x.order === null)) {
      return response.data
    } else {
      return response.data.sort(function (a, b) {
        return a - b
      })
    }
  }

  // eslint-disable-next-line max-len
  static async GetClassificationElementsWithContextDetails(
    classificationRequest: ClassificationRequestModel
  ): Promise<Array<ClassificationElementModel>> {
    const param = {
      classificationId: classificationRequest.classificationId,
      contextId: classificationRequest.contextId,
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetClassificationElementsWithContextDetails', param)
    return response.data
  }

  static async GetAllDWHCommonConceptsForElementList(contextId: number): Promise<Array<DWHCommonConceptModel>> {
    const param = {
      contextId: contextId,
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetAllDWHCommonConceptsForElementList', param)
    return response.data
  }

  static async SaveElements(request: SaveClassificationElementsRequestModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'SaveClassificationElements', request)
    return response.data
  }
}

