import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { MainStore } from '.'
import TranslationLanguageModel from '~/shared/model/Translation/TranslationLanguageModel'
import { TranslationService } from '~/shared/service/translation.service'
import TranslationSummaryGridModel from '~/shared/model/Translation/TranslationSummaryGridModel'

@Module({ namespaced: true, name: 'TranslationModule', dynamic: true, store: MainStore })
export default class TranslationModule extends VuexModule {
    AllLanguages:Array<TranslationLanguageModel> = [] as Array<TranslationLanguageModel>

    @Mutation
    setLanguage (languages:Array<TranslationLanguageModel>) {
      this.AllLanguages = languages
    }

    @Action
    async getAllLanguages (): Promise<void> {
      await TranslationService.GetAllComponentTranslationLanguages()
        .then((resp) => {
          this.setLanguage(resp)
        })
        .catch((error) => {
          throw error
        })
    }
}
