import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'
import { getModule } from 'vuex-module-decorators'
import LoginModule from '~/store/LoginModule'

const loginStore: LoginModule = getModule(LoginModule)

const auth: Middleware = (context: Context) => {
  if (context.route.name?.startsWith('auth')) {
    return
  }

  const isLoggedIn = !!loginStore.token.length

  if (isLoggedIn) {
    if (context.route.path === '/') {
      return context.redirect('/Study')
    }
    return
  }

  return context.redirect({
    name: 'auth-login'
  })
}

export default auth
