import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import UserModel from '~/shared/model/UserModel'
import UserApplicationRoleModel from '@/shared/model/UserApplicationRoleModel'
import ApplicationRoleModel from '@/shared/model/ApplicationRoleModel'
import { MainStore } from '~/store/index'
import { UserService } from '~/shared/service/user.service'

@Module({ namespaced: true, name: 'UserModule', dynamic: true, store: MainStore })
export default class UserModule extends VuexModule {
    users: Array<UserModel> = [] as Array<UserModel>
    user: UserModel = new UserModel()
    availableApplicationRoles: Array<ApplicationRoleModel> = [] as Array<ApplicationRoleModel>

    @Mutation
    addUsers (users:Array<UserModel>) {
      this.users = users
    }

    @Mutation
    setUser (user: UserModel) {
      this.user = user
    }

    @Mutation
    setAvailableApplicationRoleModels (applicationRoleModels: Array<ApplicationRoleModel>) {
      this.availableApplicationRoles = applicationRoleModels
    }

    @Action
    async getUsers (): Promise<void> {
      await UserService.getUsers()
        .then((resp) => {
          this.addUsers(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async addUser (user: UserModel): Promise<void> {
      await UserService.addUser(user)
        .then()
        .catch((error) => {
          throw error
        })
    }

    @Action
    async deleteUser (userId: number): Promise<void> {
      await UserService.deleteUser(userId)
        .then()
        .catch((error) => {
          throw error
        })
    }

    @Action
    async updateUser (user: UserModel): Promise<void> {
      await UserService.updateUser(user)
        .then()
        .catch((error) => {
          throw error
        })
    }

    @Action
    async getUserDetails (userId: number): Promise<void> {
      await UserService.getUserDetails(userId)
        .then((resp) => {
          this.setUser(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async addApplicationRoleForUser (userApplicationRoleModel: UserApplicationRoleModel): Promise<void> {
      await UserService.addApplicationRoleForUser(userApplicationRoleModel)
        .then()
        .catch((error) => {
          throw error
        })
    }

    @Action
    async checkIfUsernameAlreadyExists (username: string): Promise<boolean> {
      const response = await UserService.checkIfUsernameAlreadyExists(username)
        .then((resp) => {
          return resp
        })
        .catch((error) => {
          throw error
        })
      return response
    }
}
