import ComponentModel from '../model/ComponentModel'
import { SearchGridDetailsModel } from '../model/SearchGridDetailsModel'
import { SearchGridModel } from '../model/SearchGridModel'
import { ApiUrl } from '../util/api.url'
import ContextModel from '../model/ContextModel'
import CheckIfComponentHasContextRequestModel from '../model/CheckIfComponentHasContextRequestModel'
import LanguageValidationModel from '../model/LanguageValidationModel'
import { EditSurveySearchGridModel } from '../model/EditSurveySearchGridModel'
import { CreateComponentLanguageModel } from '../model/SearchComponent/CreateComponentLanguageModel'
import ComponentTranslation from '../model/ComponentTranslation'
import TranslationExportRequestModel from '../model/TranslationExportRequestModel'
import ComponentTranslationImportModel from '../model/ComponentTranslationImportModel'
import { BaseService } from './base.service'
import ComponentExtendedModel from '~/shared/model/ComponentExtendedModel'
import ComponentRuleModel from '~/shared/model/ComponentRuleModel'

export class ComponentService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/Component/'

    static async getAllSearchGridComponents (): Promise<Array<SearchGridModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetAllSearchGridComponents')
      return response.data
    }

    static async getAllSearchGridComponentsByContextId (contextId:number): Promise<Array<EditSurveySearchGridModel>> {
      const param = {
        contextId: contextId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'getAllSearchGridComponentsByContextId', param)
      return response.data
    }

    static async getComponentDetailsByComponentId (componentId:number): Promise<Array<SearchGridDetailsModel>> {
      const param = {
        Id: componentId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetComponentDetailsByComponentId', param)
      return response.data
    }

    static async GetQuestionsAndDimensionByComponentId (componentId: number, contextId: number): Promise<ComponentModel> {
      const param = {
        componentId: componentId,
        contextId: contextId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetQuestionsAndDimensionByComponentId', param)

      return response.data
    }

    // eslint-disable-next-line max-len
    static async getComponentExportDocument (componentId: number, metadata: boolean, contextId: number): Promise<any> {
      const param = {
        componentId: componentId,
        metadata: metadata,
        contextId: contextId
      }
      const response = await this.FileDownload(this.baseUrl + 'GetComponentExportDocument', param)
      return response.data
    }

    static async getEditComponentPageTitle (componentId: number): Promise<string> {
      const response = await this.getApiCall(`${this.baseUrl}GetEditComponentPageTitle?componentId=${componentId}`)
      return response.data
    }

    static async getAllExtendedComponentsByComponentId (componentId: number, contextId: number): Promise<Array<ComponentExtendedModel>> {
      const response =
      await this.getApiCall(`${this.baseUrl}GetAllExtendedComponentsByComponentId?componentId=${componentId}&contextId=${contextId}`)
      return response.data
    }

    static async getComponentMetadataByComponentIdAsync (componentId: number, contextId: number): Promise<ComponentModel> {
      const response = await this.getApiCall(`${this.baseUrl}GetComponentById?componentId=${componentId}&contextId=${contextId}`)
      return response.data
    }

    static async getContexts (): Promise<Array<ContextModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetContexts')
      return response.data
    }

    static async GetLanguages (): Promise<Array<CreateComponentLanguageModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetLanguages')
      return response.data
    }

    static async saveComponent (component:ComponentModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveComponent', component)
      return response.data
    }

    static async deleteComponentById (componentId:number):Promise<void> {
      const param = {
        componentId: componentId
      }
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteComponentById', param)

      return response.data
    }

    static async checkIfComponentHasContext (request:CheckIfComponentHasContextRequestModel):Promise<LanguageValidationModel> {
      const response = await this.postApiCall(this.baseUrl + 'checkIfComponentHasContext', request)
      return response.data
    }

    static async saveComponentExtended (component:ComponentExtendedModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveComponentExtended', component)
      return response.data
    }

    static async saveComponentExtendeds (component:Array<ComponentExtendedModel>): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveComponentExtendeds', component)
      return response.data
    }

    static async getComponentTypesAsync () {
      const response = await this.getApiCall(this.baseUrl + 'GetComponentTypes')
      return response.data
    }

    static async saveComponentRule (componentRule:ComponentRuleModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveComponentRule', componentRule)
      return response.data
    }

    static async deleteComponentRule (componentRuleId: number): Promise<void> {
      const param = {
        componentRuleId: componentRuleId
      }
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteComponentRule', param)
      return response.data
    }

    static async getTranslationSummaryByComponentId (componentId:number):Promise<Array<ComponentTranslation>> {
      const param = {
        componentId: componentId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'getTranslationSummaryByComponentId', param)
      return response.data
    }

    static async getContextIdsForSelectedComponents (componentIds: Array<number>): Promise<Array<number>> {
      const response = await this.postApiCall(this.baseUrl + 'ContextIdsForSelectedComponents', componentIds)
      return response.data
    }
}
