import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import PropertyDataTypeModel from '~/shared/model/PropertyDataTypeModel'
import PropertyValueTypeModelLookUp from '~/shared/model/PropertyValueTypeModelLookUp'
import DropDownValueContextsByPropertyDataTypeModel from '~/shared/model/DropDownValueContextsByPropertyDataTypeModel'
import ContextModel from '~/shared/model/ContextModel'
import { DropdownValueModel } from '~/shared/model/DropdownValueModel'
import { DropdownValueUIAddModel, DataTypeValueUIUpdateModel, ValuesLanguageSynonymUIModel } from '~/shared/model/ui-specific/UIModelsPropertyDataType'
import { MainStore } from '~/store/index'
import { PropertyDataTypeService } from '~/shared/service/propertydatatype.service'

@Module({ namespaced: true, name: 'PropertyDataTypeModule', dynamic: true, store: MainStore })

export default class PropertyModule extends VuexModule {
    propertyDataTypes: Array<PropertyDataTypeModel> = [] as Array<PropertyDataTypeModel>
    dropdownValueContexts: Array<DropDownValueContextsByPropertyDataTypeModel> = [] as Array<DropDownValueContextsByPropertyDataTypeModel>
    contexts: Array<ContextModel> = [] as Array<ContextModel>
    dropdownValues: Array<DropdownValueModel> = [] as Array<DropdownValueModel>
    dropdownValuesNewItem: Array<DropDownValueContextsByPropertyDataTypeModel> = [] as Array<DropDownValueContextsByPropertyDataTypeModel>
    dropdownValuesNewLanguage: Array<DropDownValueContextsByPropertyDataTypeModel> = [] as Array<DropDownValueContextsByPropertyDataTypeModel>
    dataTypeDropdownValuesForLanguage: Array<DropDownValueContextsByPropertyDataTypeModel> = [] as Array<DropDownValueContextsByPropertyDataTypeModel>

    @Mutation
    addAllPropertyDataTypes (propertyDataTypes:Array<PropertyDataTypeModel>) {
      this.propertyDataTypes = propertyDataTypes
    }

    @Mutation
    addDropDownValueContexts (dropdownValueContexts:Array<DropDownValueContextsByPropertyDataTypeModel>) {
      this.dropdownValueContexts = dropdownValueContexts
    }

    @Mutation
    addContexts (contexts:Array<ContextModel>) {
      this.contexts = contexts
    }

    @Mutation
    setDropdownValuesNewItem (propertyValueTypeId: number) {
      // initialize store
      this.dropdownValuesNewItem = []

      // retrieve property data type
      const pdt = this.propertyDataTypes.filter(p => p.id === propertyValueTypeId)

      // the new item must have language synonyms for each existing property datatype language
      pdt[0].contextIds.forEach((value) => {
        // retrieve context data
        const context = this.contexts.filter(c => c.contextId === value)
        const newItem: DropDownValueContextsByPropertyDataTypeModel = {
          propertyValueTypeId: pdt[0].id,
          dropdownValueId: 0,
          position: pdt[0].valueIds.length + 1,
          commonConceptId: 0,
          defaultContextId: pdt[0].defaultContextId,
          defaultContext: value === pdt[0].defaultContextId,
          synonymId: 0,
          synonymName: '',
          contextId: value,
          contextName: context[0].contextName,
          contextAlias: context[0].alias,
          contextDisplayName: context[0].contextDisplayName
        }
        this.dropdownValuesNewItem.push(newItem)
      })
    }

    @Mutation
    setDataTypeDropdownValuesForLanguage (payload: {propertyValueTypeId: number, contextid: number}) {
      const dataTypeDropdownValuesForLanguage = this.dropdownValueContexts
        .filter(dd => dd.propertyValueTypeId === payload.propertyValueTypeId &&
            dd.contextId === payload.contextid
        )
      this.dataTypeDropdownValuesForLanguage = dataTypeDropdownValuesForLanguage
    }

    @Mutation
    setDropdownValuesNewLanguage (contextId: number) {
      // initialize store
      this.dropdownValuesNewLanguage = []
      const defaultvalues = this.dropdownValueContexts.filter(p => p.defaultContext)
      defaultvalues.forEach((value) => {
        const newLanguageItem: DropDownValueContextsByPropertyDataTypeModel = {
          propertyValueTypeId: value.propertyValueTypeId,
          dropdownValueId: value.dropdownValueId,
          position: value.position,
          commonConceptId: value.commonConceptId,
          defaultContextId: value.contextId,
          defaultContext: false,
          synonymId: 0,
          synonymName: '',
          contextId: contextId,
          contextName: '',
          contextAlias: '',
          contextDisplayName: ''
        }
        this.dropdownValuesNewLanguage.push(newLanguageItem)
      })
    }

    @Action
    async getAllPropertyDataTypes (): Promise<void> {
      await PropertyDataTypeService.getAllPropertyDataTypes()
        .then((resp) => {
          this.addAllPropertyDataTypes(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async getDropDownValueContextsByPropertyDataType (propertyValueTypeId: number): Promise<void> {
      await PropertyDataTypeService.GetDropDownValueContextsByPropertyDataType(propertyValueTypeId)
        .then((resp) => {
          this.addDropDownValueContexts(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async AddPropertyValueType (propertyDataTypeName: string): Promise<void> {
      await PropertyDataTypeService.AddPropertyValueType(propertyDataTypeName)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async UpdatePropertyValueType (propertyValueType: PropertyValueTypeModelLookUp): Promise<void> {
      await PropertyDataTypeService.UpdatePropertyValueType(propertyValueType)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async DeletePropertyValueType (id: number): Promise<void> {
      await PropertyDataTypeService.DeletePropertyValueType(id)
        .then()
        .catch((error) => {
          // return error.response;
          throw error
        })
    }

    @Action
    async getContexts (): Promise<void> {
      await PropertyDataTypeService.getAllContexts()
        .then((resp) => {
          this.addContexts(resp)
        })
        .catch((error) => {
          throw error
        })
    }

    @Action
    async AddDataTypeValue (actionAddValueSet: {propertyValueTypeId: number, dropdownValue: Array<DropdownValueUIAddModel>}): Promise<void> {
      await PropertyDataTypeService.AddDataTypeValue(actionAddValueSet.propertyValueTypeId, actionAddValueSet.dropdownValue)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async UpdateDataTypeValue (dataTypeValue: DataTypeValueUIUpdateModel): Promise<void> {
      await PropertyDataTypeService.UpdateDataTypeValue(dataTypeValue)
        .catch((error) => {
          throw error
        })
    }

    @Action
    async DeleteDataTypeValue (actionDeleteValuePayload: {propertyValueTypeId: number, dropdownValueId: number}): Promise<void> {
      await PropertyDataTypeService.DeleteDataTypeValue(actionDeleteValuePayload.propertyValueTypeId, actionDeleteValuePayload.dropdownValueId)
        .then()
        .catch((error) => {
          // return error.response;
          throw error
        })
    }

    @Action
    async AddDataTypeValueLanguage (addLanguagePayload: {propertyValueTypeId: number, contextId: number, synonym: Array<ValuesLanguageSynonymUIModel>}): Promise<void> {
      await PropertyDataTypeService.AddDataTypeValueLanguage(addLanguagePayload.propertyValueTypeId, addLanguagePayload.contextId, addLanguagePayload.synonym)
        .then()
        .catch((error) => {
          // return error.response;
          throw error
        })
    }

    @Action
    async DeleteDataTypeValueLanguage (actionDeleteLanguagePayload: {propertyValueTypeId: number, contextId: number}): Promise<void> {
      await PropertyDataTypeService.DeleteDataTypeValueLanguage(actionDeleteLanguagePayload.propertyValueTypeId, actionDeleteLanguagePayload.contextId)
        .then()
        .catch((error) => {
          // return error.response;
          throw error
        })
    }
}
