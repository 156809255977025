import Axios from 'axios'
import { ChangePasswordModel } from '../model/ChangePasswordModel'
import FunctionModel from '../model/FunctionModel'
import LoginInformation from '../model/LoginInformation'
import { UserProfileModel } from '../model/UserProfileModel'
import { ApiUrl } from '../util/api.url'
import { BaseService } from './base.service'

export class AuthenticationService extends BaseService {
  private static defaultsAxios = Axios.create()
  private static baseUrl = ApiUrl.webapiurl() + '/Authentication/'

  static async login (loginInformation: LoginInformation): Promise<UserProfileModel> {
    const response = await this.defaultsAxios
      .post(this.baseUrl + 'Login', loginInformation)
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        throw error
      })
    return response.data
  }

  static async GetUserInformation (): Promise<string> {
    const response = await this.getApiCall(this.baseUrl + 'GetUserInformation')
    return response.data
  }

  static async GetApplicationFunctions (): Promise<FunctionModel> {
    const response = await this.getApiCall(this.baseUrl + 'GetApplicationFunctions')
    return response.data
  }

  static async ChangePassword (changePassword:ChangePasswordModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'ChangePassword', changePassword)
    return response.data
  }

  static async ForgotPassword (email:string): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'ForgotPassword', email)
    return response.data
  }
}
