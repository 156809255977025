import Vuex, { Store } from 'vuex'
import LoaderModule from '@core/store/LoaderModule'
import Vue from 'vue'
import { config } from 'vuex-module-decorators'
import { getModule } from 'nuxt-property-decorator'
import LoginModule from './LoginModule'
import { ErrorModel } from '~/shared/model/ErrorModel'
import { ErrorMessageModel } from '~/shared/model/ErrorMessageModel'

Vue.use(Vuex)

Vue.config.errorHandler = (error) => {
  const store = getModule(LoginModule)

  const message = new ErrorMessageModel({
    message: error.message,
    fullErrorMessage: error.message
  })

  const errorModel = new ErrorModel({
    errorCode: 500,
    message: message
  })

  // Add error stuff to store
  store.addError(errorModel)
  // console.error(error)
}

// Set rawError to true by default on all @Action decorators
config.rawError = true

export const MainStore: Store<any> = new Vuex.Store<any>({
  modules: {
    loaderModule: LoaderModule
  }
})

export default () => {
  return MainStore
}
