import Axios from 'axios'
import { getModule, Vue } from 'nuxt-property-decorator'
import { ErrorModel } from '../model/ErrorModel'
import LoginModule from '~/store/LoginModule'

export class BaseService extends Vue {
  public static async getApiCallWithParam (urlPath:string, parameter:any): Promise<any> {
    const store = getModule(LoginModule)
    store.resetErrorState()
    const cdpAxios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    const response = await cdpAxios
      .get(urlPath,
        {
          params: parameter
        })
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        const errorResponse = BaseService.Handle401(error)
        throw errorResponse
      })
    return response
  }

  public static async getApiCall (urlPath:string): Promise<any> {
    const store = getModule(LoginModule)
    store.resetErrorState()
    const cdpAxios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    const response = await cdpAxios
      .get(urlPath)
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        const errorResponse = BaseService.Handle401(error)
        throw errorResponse
      })
    return response
  }

  public static async postApiCall (urlPath:string, object:any): Promise<any> {
    const store = getModule(LoginModule)
    store.resetErrorState()
    const cdpAxios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    const response = await cdpAxios
      .post(urlPath, object)
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        const errorResponse = BaseService.Handle401(error)
        throw errorResponse
      })
    return response
  }

  public static async FileUpload (urlPath:string, object:any): Promise<any> {
    const store = getModule(LoginModule)
    store.resetErrorState()
    const cdpAxios = Axios.create({
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    const response = await cdpAxios
      .post(urlPath, object)
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        const errorResponse = BaseService.Handle401(error)
        throw errorResponse
      })
    return response
  }

  public static async FileDownload (urlPath:string, parameter:any): Promise<any> {
    const store = getModule(LoginModule)
    store.resetErrorState()
    const cdpAxios = Axios.create({
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    const response = await cdpAxios
      .get(urlPath,
        {
          params: parameter
        })
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        const errorResponse = BaseService.Handle401(error)
        throw errorResponse
      })
    return response
  }

  public static async FilePostDownload (urlPath:string, object:any): Promise<any> {
    const store = getModule(LoginModule)
    store.resetErrorState()
    const cdpAxios = Axios.create({
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    const response = await cdpAxios
      .post(urlPath, object)
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        const errorResponse = BaseService.Handle401(error)
        throw errorResponse
      })
    return response
  }

  public static async postApiCallWithParam (urlPath:string, object:any, parameter:any): Promise<any> {
    const store = getModule(LoginModule)
    store.resetErrorState()
    const cdpAxios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    const response = await cdpAxios
      .post(urlPath, object, {
        params: parameter
      })
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        const errorResponse = BaseService.Handle401(error)
        throw errorResponse
      })
    return response
  }

  public static async deleteApiCall (urlPath:string, parameter:any): Promise<any> {
    const store = getModule(LoginModule)
    store.resetErrorState()
    const cdpAxios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    const response = await cdpAxios
      .delete(urlPath,
        {
          params: parameter
        })
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        const errorResponse = BaseService.Handle401(error)
        throw errorResponse
      })
    return response
  }

  public static Handle401 (error:any): ErrorModel {
    const store = getModule(LoginModule)
    // Get error stuff
    const statusCode = error.response ? error.response.status : null
    const message = error.response ? error.response.data : null

    // Put error stuff in model
    const errorModel = new ErrorModel({
      errorCode: statusCode,
      message: message
    })

    // Add error stuff to store
    store.addError(errorModel)

    // Custom error handling when 401
    if (statusCode === 401) {
      store.logout()
      window.location.pathname = '/auth/login'
    }

    // give back the error
    return errorModel
  }
}
