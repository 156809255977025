import { CreateSurveyComponentModel } from '../model/CreateSurveyComponentModel'
import SurveyComponentExtendedModel from '../model/SurveyComponentExtendedModel'
import SurveyComponentModel from '../model/SurveyComponentModel'
import SurveyComponentRuleModel from '../model/SurveyComponentRuleModel'
import { ApiUrl } from '../util/api.url'
import { BaseService } from './base.service'

export class SurveyComponentService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/SurveyComponent/'

    static async saveComponent (component:SurveyComponentModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSurveyComponent', component)
      return response.data
    }

    static async deleteComponentById (componentId:number):Promise<void> {
      const param = {
        componentId: componentId
      }
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteSurveyComponentById', param)

      return response.data
    }

    static async saveComponentExtended (component:SurveyComponentExtendedModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveComponentExtended', component)
      return response.data
    }

    static async saveComponentExtendeds (components: Array<SurveyComponentExtendedModel>): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveComponentExtendeds', components)
      return response.data
    }

    static async addSurveyComponents (model:CreateSurveyComponentModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'AddSurveyComponents', model)
      return response.data
    }

    static async copySurveyComponent (componentId:number): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'copySurveyComponent', componentId)
      return response.data
    }

    static async saveComponentRule (componentRule: SurveyComponentRuleModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveComponentRule', componentRule)
      return response.data
    }

    static async deleteComponentRule (surveyComponentRuleId: number): Promise<void> {
      const param = {
        surveyComponentRuleId: surveyComponentRuleId
      }
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteComponentRule', param)
      return response.data
    }
}
