import { StudyGridModel } from '../model/Study/StudyGridModel'
import { ApiUrl } from '../util/api.url'
import { CreateStudyRequestModel } from '../model/Study/CreateStudyRequestModel'
import { StudyDetailsModel } from '../model/Study/StudyDetailsModel'
import { SurveyLinkModel } from '../model/Study/SurveyLinkModel'
import { StudyDatasetModel } from '../model/Study/StudyDatasetModel'
import { StudyPermissionModel } from '../model/Study/StudyPermissionModel'
import { StudyFunctionModel } from '../model/Study/StudyFunctionModel'
import { SaveUserFunctionRequest } from '../model/Study/SaveUserFunctionRequest'
import { StudyLinkModel } from '../model/Study/StudyLinkModel'
import { StudyLinkModelRequest } from '../model/Study/StudyLinkModelRequest'
import { StudyDetailPopUpModel } from '../model/Study/StudyDetailPopUpModel'
import { StudyShareModel } from './../model/Study/StudyShareModel'
import { BaseService } from './base.service'
import { StudyMetadataModel } from '~/shared/model/Study/StudyMetadataModel'
import UserRoleStudyModel from '~/shared/model/Study/UserRoleStudyModel'
import { UpdateStudyShareModel } from '~/shared/model/Study/UpdateStudyShareModel'

export class studyService extends BaseService {
  private static baseUrl = ApiUrl.webapiurl() + '/Study/'

  static async GetAllStudies (): Promise<Array<StudyGridModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetAllStudies')
    return response.data
  }

  static async GetStudyDetailsByStudyId (studyId: number): Promise<StudyDetailsModel> {
    const param = {
      studyId: studyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetStudyDetailsByStudyId', param)
    return response.data
  }

  static async GetStudyPopUpDetailsByStudyId (studyId: number): Promise<Array<StudyDetailPopUpModel>> {
    const param = {
      studyId: studyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetStudyPopUpDetailsByStudyId', param)
    return response.data
  }

  static async GetStudyMetadata (studyId: number): Promise<Array<StudyMetadataModel>> {
    const param = {
      studyId: studyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetStudyMetadata', param)
    return response.data
  }

  static async GetDatasetsByStudyId (studyId: number): Promise<Array<StudyDatasetModel>> {
    const param = {
      studyId: studyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetDatasetsByStudyId', param)
    return response.data
  }

  static async GetSurveyLinksByStudyId (studyId: number): Promise<Array<SurveyLinkModel>> {
    const param = {
      studyId: studyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetSurveyLinksByStudyId', param)
    return response.data
  }

  static async GetExternalLinksByStudyId (studyId: number): Promise<Array<StudyLinkModel>> {
    const param = {
      studyId: studyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetExternalLinksByStudyId', param)
    return response.data
  }

  static async SaveStudyMetadata (metadata: StudyMetadataModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'SaveStudyMetadata', metadata)
    return response.data
  }

  static async DeleteStudyById (studyId:number): Promise<void> {
    const param = {
      studyId: studyId
    }
    const response = await this.deleteApiCall(this.baseUrl + 'DeleteStudyById', param)
    return response.data
  }

  static async CreateStudy (request: CreateStudyRequestModel): Promise<number> {
    const response = await this.postApiCall(this.baseUrl + 'CreateStudy', request)
    return response.data
  }

  static async GetActiveUsers (): Promise<Array<UserRoleStudyModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetActiveUsers')
    return response.data
  }

  static async AddUserToStudy (request: StudyShareModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'AddUserToStudy', request)
    return response.data
  }

  static async DeleteUserFromStudy (request: UpdateStudyShareModel): Promise<void> {
    const param = {
      userId: request.userId,
      studyId: request.studyId
    }
    const response = await this.deleteApiCall(this.baseUrl + 'DeleteUserFromStudy', param)
    return response.data
  }

  static async UploadDataset (file:any, name:string, studyId:number): Promise<number> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', name)
    formData.append('studyId', studyId.toString())

    const response = await this.FileUpload(this.baseUrl + 'UploadDataset', formData)
    return response.data
  }

  static async DeleteDatasetById (datasetId:number): Promise<void> {
    const param = {
      datasetId: datasetId
    }
    const response = await this.deleteApiCall(this.baseUrl + 'DeleteDatasetById', param)
    return response.data
  }

  static async UpdateUserRolesForStudy (request: UpdateStudyShareModel) {
    const response = await this.postApiCall(this.baseUrl + 'UpdateUserRolesForStudy', request)
    return response.data
  }

  static async DownloadDatasetById (datasetId:number): Promise<any> {
    const param = {
      datasetId: datasetId
    }
    const response = await this.FileDownload(this.baseUrl + 'DownloadDatasetById', param)
    return response.data
  }

  static async UpdateStudyDetails (request: StudyDetailsModel) {
    const response = await this.postApiCall(this.baseUrl + 'UpdateStudyDetails', request)
    return response.data
  }

  static async GetDatasetPermissions (studyId: number, datasetId:number): Promise<Array<StudyPermissionModel>> {
    const param = {
      studyId: studyId,
      datasetId: datasetId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetDatasetPermissions', param)
    return response.data
  }

  static async GetSurveyPermissions (studyId: number, surveyId:number): Promise<Array<StudyPermissionModel>> {
    const param = {
      studyId: studyId,
      surveyId: surveyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetSurveyPermissions', param)
    return response.data
  }

  static async GetDatasetFunctions (): Promise<Array<StudyFunctionModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetDatasetFunctions')
    return response.data
  }

  static async GetSurveyFunctions (): Promise<Array<StudyFunctionModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetSurveyFunctions')
    return response.data
  }

  static async SaveUserDatasetPermissions (request:SaveUserFunctionRequest): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'SaveUserDatasetPermissions', request)
    return response.data
  }

  static async SaveUserSurveyPermissions (request:SaveUserFunctionRequest): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'SaveUserSurveyPermissions', request)
    return response.data
  }

  static async AddExternalUrl (studyLink:StudyLinkModelRequest):Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'AddExternalLinkToStudy', studyLink)
    return response.data
  }

  static async UpdateExternalLinkForStudy (studyLink:StudyLinkModelRequest):Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'UpdateExternalLinkForStudy', studyLink)
    return response.data
  }

  static async DeleteExternalUrlById (studyLinkId: number):Promise<void> {
    const param = {
      studyLinkId: studyLinkId
    }
    const response = await this.deleteApiCall(this.baseUrl + 'DeleteExternalUrlById', param)
    return response.data
  }
}
