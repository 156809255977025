import Axios from 'axios'
import CdpTypeValueModel from '../model/CdpTypeValueModel'
import { ApiUrl } from '../util/api.url'
import AddCdpTypeValueModel from '../model/AddCdpTypeValueModel'
import { BaseService } from './base.service'
import ComponentMetadataPropertyModel from '~/shared/model/ComponentMetadataPropertyModel'
import QuestionPropertyModel from '~/shared/model/QuestionPropertyModel'
import { PropertyValueTypeModel } from '~/shared/model/PropertyValueTypeModel'
import PropertValueTypeModelLookUp from '~/shared/model/PropertyValueTypeModelLookUp'

export class PropertyService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/CdpProperties/'

    static async getAllQuestionCdpTypeValues (): Promise<Array<CdpTypeValueModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetAllQuestionCdpTypeValues')
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async getAllComponentCdpTypeValues (): Promise<Array<CdpTypeValueModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetAllComponentCdpTypeValues')
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async AddCdpComponentValueType (cdpValueType: AddCdpTypeValueModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'AddCdpComponentValueType', cdpValueType)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async AddCdpQuestionValueType (cdpValueType: AddCdpTypeValueModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'AddCdpQuestionValueType', cdpValueType)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async UpdateCdpValueType (cdpValueType: CdpTypeValueModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'UpdateCdpValueType', cdpValueType)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async DeleteCdpValueType (cdpValueTypeId: number): Promise<void> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteCdpValueType?cdpTypeValueId=' + cdpValueTypeId, null)
        .catch((error) => {
          throw error.message // .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async getQuestionPropertiesByCdpTypeValue (cdpTypeValueId: number): Promise<Array<QuestionPropertyModel>> {
      const param = {
        cdpTypeValueId: cdpTypeValueId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetQuestionPropertiesByCdpTypeValue/', param)
        .catch((error) => {
          throw error.message // .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async getComponentPropertiesByCdpTypeValue (cdpTypeValueId: number): Promise<Array<ComponentMetadataPropertyModel>> {
      const param = {
        cdpTypeValueId: cdpTypeValueId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetComponentPropertiesByCdpTypeValue/', param)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async AddComponentMetadataProperty (componentMetadataProperty: ComponentMetadataPropertyModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'AddComponentMetadataProperty', componentMetadataProperty)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async UpdateComponentMetadataProperty (componentMetadataProperty: ComponentMetadataPropertyModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'UpdateComponentMetadataProperty', componentMetadataProperty)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async DeleteComponentMetadataProperty (id: number): Promise<void> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteComponentMetadataProperty?componentMetadataPropertyId=' + id, null)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async AddQuestionProperty (questionProperty: QuestionPropertyModel): Promise<void> {
      const newProperty = <QuestionPropertyModel>{
        name: questionProperty.name,
        valueTypeId: questionProperty.valueTypeId,
        questionType: questionProperty.questionType
      }
      const response = await this.postApiCall(this.baseUrl + 'AddQuestionProperty', newProperty)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async UpdateQuestionProperty (questionProperty: QuestionPropertyModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'UpdateQuestionProperty', questionProperty)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async DeleteQuestionProperty (id: number): Promise<void> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteQuestionProperty?questionPropertyId=' + id, null)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async getAllPropertyValueTypesLookUp (): Promise<Array<PropertValueTypeModelLookUp>> {
      const response = await this.getApiCall(this.baseUrl + 'GetAllPropertyValueTypesLookUp')
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }

    static async getAllPropertyValueTypes (contextId:number): Promise<Array<PropertyValueTypeModel>> {
      const param = {
        contextId: contextId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetPropertyValueTypes', param)
        .catch((error) => {
          throw error.message// .substring(0, error.message.indexOf('at Cdp'))
        })
      return response.data
    }
}
