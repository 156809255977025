import { Guid } from 'guid-typescript'
import _ from 'lodash'

export interface IUserProfileModel {
  id: string
}

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class UserProfileModel implements IUserProfileModel {
  private _username: ValueOrFactory<string>
  private _firstname: ValueOrFactory<string>
  private _surname: ValueOrFactory<string>
  private _email: ValueOrFactory<string>
  private _token: ValueOrFactory<string>
  private _forceChange:ValueOrFactory<boolean>
  private _admin: ValueOrFactory<boolean>

  get username (): string {
    return this.getValue(this._username)
  }

  get id (): string {
    return Guid.create().toString()
  }

  get email (): string {
    return this.getValue(this._email)
  }

  get firstname (): string {
    return this.getValue(this._firstname)
  }

  get surname (): string {
    return this.getValue(this._surname)
  }

  get token (): string {
    return this.getValue(this._token)
  }

  get forceChange (): boolean {
    return this.getValue(this._forceChange)
  }

  get admin (): boolean {
    return this.getValue(this._admin)
  }

  constructor (init?: PartialValueOrFactory<UserProfileModel>) {
    (Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T> (value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
