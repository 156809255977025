import PropertyDataTypeModel from '../model/PropertyDataTypeModel'
import PropertyValueTypeModelLookUp from '../model/PropertyValueTypeModelLookUp'
import DropDownValueContextsByPropertyDataTypeModel from '../model/DropDownValueContextsByPropertyDataTypeModel'
import {
  DropdownValueUIAddModel,
  DataTypeValueUIUpdateModel,
  ValuesLanguageSynonymUIModel
} from '../model/ui-specific/UIModelsPropertyDataType'
import ContextModel from '../model/ContextModel'
import { ApiUrl } from '../util/api.url'
import AddPropertyValueTypeRequest from '../model/AddPropertyValueTypeRequest'
import { BaseService } from './base.service'

export class PropertyDataTypeService extends BaseService {
  private static baseUrl = ApiUrl.webapiurl() + '/PropertyDataType/'

  static async getAllPropertyDataTypes (): Promise<Array<PropertyDataTypeModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetAllPropertyDataTypes')
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  static async AddPropertyValueType (propertyDataTypeName: string): Promise<void> {
    const request = new AddPropertyValueTypeRequest()
    request.propertyDataTypeName = propertyDataTypeName
    const response = await this.postApiCall(this.baseUrl + 'AddPropertyValueType', request)
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  static async UpdatePropertyValueType (propertyValueTypeModel: PropertyValueTypeModelLookUp): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'UpdatePropertyValueType', propertyValueTypeModel)
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  static async DeletePropertyValueType (id: number): Promise<void> {
    const response = await this.deleteApiCall(this.baseUrl + 'DeletePropertyValueType?propertyValueTypeId=' + id, null)
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  static async GetDropDownValueContextsByPropertyDataType (propertyValueTypeId: number):
   Promise<Array<DropDownValueContextsByPropertyDataTypeModel>> {
    const param = {
      propertyValueTypeId: propertyValueTypeId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetDropDownValueContextsByPropertyDataType/', param)
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  static async getAllContexts (): Promise<Array<ContextModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetAllContexts')
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  static async AddDataTypeValue (propertyValueTypeId: number, dropdownValue: Array<DropdownValueUIAddModel>): Promise<void> {
    const param = { propertyValueTypeId }
    const response = await this.postApiCallWithParam(this.baseUrl + 'AddDataTypeValue', dropdownValue, param)
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  static async UpdateDataTypeValue (updateDataTypeValueModel: DataTypeValueUIUpdateModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'UpdateDataTypeValue', updateDataTypeValueModel)
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  static async DeleteDataTypeValue (propertyValueTypeId: number, dropdownValueId: number): Promise<void> {
    const response = await this.deleteApiCall(this.baseUrl +
       'DeleteDataTypeValue?propertyValueTypeId=' +
     propertyValueTypeId + '&dropdownValueId=' + dropdownValueId, null)
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
    return response.data
  }

  // eslint-disable-next-line max-len
  static async AddDataTypeValueLanguage (propertyValueTypeId: number, contextId: number, synonym: Array<ValuesLanguageSynonymUIModel>): Promise<void> {
    await this.postApiCallWithParam(this.baseUrl + 'AddDataTypeValueLanguage', synonym,
      { propertyValueTypeId: propertyValueTypeId, contextId: contextId }
    )
      .then(() => {
      })
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
  }

  static async DeleteDataTypeValueLanguage (propertyValueTypeId: number, contextId: number): Promise<void> {
    // eslint-disable-next-line max-len
    await this.deleteApiCall(`${this.baseUrl}DeleteDataTypeValueLanguage?propertyValueTypeId=${propertyValueTypeId}&contextId=${contextId}`, null)
      .then(() => {
      })
      .catch((error) => {
        throw error.message// .substring(0, error.message.indexOf('at Cdp'))
      })
  }
}
