import _ from 'lodash'

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class SurveyModel {
  private _surveyId: ValueOrFactory<number>
  private _statusId: ValueOrFactory<number>
  private _surveyTitle: ValueOrFactory<string>
  private _researchQuestion: ValueOrFactory<string>
  private _designator: ValueOrFactory<string>
  private _createdUserId: ValueOrFactory<number>
  private _ownerDisplayName: ValueOrFactory<string>
  private _currentUserIsOwner: ValueOrFactory<boolean>
  private _surveyOwnerId: ValueOrFactory<number>
  private _contextName: ValueOrFactory<string>
  private _ownerEmail:ValueOrFactory<string>
  private _canEdit:ValueOrFactory<boolean>
  private _canDelete:ValueOrFactory<boolean>
  private _canExport:ValueOrFactory<boolean>
  private _canExportWithMetadata:ValueOrFactory<boolean>
  private _displayRole:ValueOrFactory<string>

  get surveyId (): number {
    return this.getValue(this._surveyId)
  }

  get statusId (): number {
    return this.getValue(this._statusId)
  }

  get surveyTitle (): string {
    return this.getValue(this._surveyTitle)
  }

  get researchQuestion (): string {
    return this.getValue(this._researchQuestion)
  }

  get designator (): string {
    return this.getValue(this._designator)
  }

  get createdUserId (): number {
    return this.getValue(this._createdUserId)
  }

  get ownerDisplayName (): string {
    return this.getValue(this._ownerDisplayName)
  }

  get currentUserIsOwner (): boolean {
    return this.getValue(this._currentUserIsOwner)
  }

  get surveyOwnerId (): number {
    return this.getValue(this._surveyOwnerId)
  }

  get contextName (): string {
    return this.getValue(this._contextName)
  }

  get ownerEmail (): string {
    return this.getValue(this._ownerEmail)
  }

  get canDelete (): boolean {
    return this.getValue(this._canDelete)
  }

  get canEdit (): boolean {
    return this.getValue(this._canEdit)
  }

  get canExport (): boolean {
    return this.getValue(this._canExport)
  }

  get canExportWithMetadata (): boolean {
    return this.getValue(this._canExportWithMetadata)
  }

  get displayRole (): string {
    return this.getValue(this._displayRole)
  }

  constructor (init?: PartialValueOrFactory<SurveyModel>) {
    (Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T> (value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
