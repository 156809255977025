import Axios from 'axios'
import { ApiUrl } from '../util/api.url'
import ComponentQuestionExtendedModel from '../model/ComponentQuestionExtendedModel'
import ComponentQuestionModel from '../model/ComponentQuestionModel'
import CdpTypeValueModel from '../model/CdpTypeValueModel'
import MatrixModel from '../model/Question/MatrixModel'
import OpenQuestionModel from '../model/Question/OpenQuestionModel'
import SingleChoiceModel from '../model/Question/SingleChoiceModel'
import QuestionCodeValidationRequestModel from '../model/Question/QuestionCodeValidationRequestModel'
import BipolarModel from '../model/Question/BipolarModel'
import BipolarRequestModel from '../model/Question/BipolarRequestModel'
import { BaseService } from './base.service'

export class QuestionService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/Questions/'

    static async saveQuestion (question:ComponentQuestionModel): Promise<Array<void>> {
      const response = await this.postApiCall(this.baseUrl + 'SaveQuestion', question)
      return response.data
    }

    static async saveQuestionExtended (questionExtended: ComponentQuestionExtendedModel): Promise<Array<void>> {
      const response = await this.postApiCall(this.baseUrl + 'SaveQuestionExtended', questionExtended)
      return response.data
    }

    static async deleteQuestion (questionId:number): Promise<Array<void>> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteQuestion', { questionId: questionId })
      return response.data
    }

    static async getQuestionTypesAsync (): Promise<Array<CdpTypeValueModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetQuestionTypes')
      return response.data
    }

    static async getMatrixProperties (contextId:number, questionId:number): Promise<MatrixModel> {
      const param = {
        contextId: contextId,
        questionId: questionId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetMatrixProperties', param)
      return response.data
    }

    static async GetOpenProperties (contextId:number, questionId:number): Promise<OpenQuestionModel> {
      const param = {
        contextId: contextId,
        questionId: questionId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetOpenProperties', param)
      return response.data
    }

    static async GetSingleChoiceProperties (contextId:number, questionId:number): Promise<SingleChoiceModel> {
      const param = {
        contextId: contextId,
        questionId: questionId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetSingleChoiceProperties', param)
      return response.data
    }

    static async SaveMatrixProperties (request:MatrixModel):Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveMatrixProperties', request)
      return response.data
    }

    static async SaveOpenProperties (request:OpenQuestionModel):Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveOpenProperties', request)
      return response.data
    }

    static async SaveSingleChoiceProperties (request:SingleChoiceModel):Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveSingleChoiceProperties', request)
      return response.data
    }

    static async getMatrixTypes (): Promise<Array<CdpTypeValueModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetMatrixTypes')
      return response.data
    }

    static async getMatrixAnswerTypes (): Promise<Array<CdpTypeValueModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetMatrixAnswerTypes')
      return response.data
    }

    static async getMatrixFormatTypes (): Promise<Array<CdpTypeValueModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetMatrixFormatTypes')
      return response.data
    }

    static async ValidateComponentQuestionCode (request :QuestionCodeValidationRequestModel): Promise<boolean> {
      const response = await this.postApiCall(this.baseUrl + 'ValidateComponentQuestionCode', request)
      return response.data
    }

    static async GetBipolarProperties (contextId:number, questionId:number): Promise<Array<BipolarModel>> {
      const param = {
        contextId: contextId,
        questionId: questionId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetBipolarProperties', param)
      return response.data
    }

    static async SaveBipolarProperties (request:BipolarRequestModel):Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'SaveBipolarProperties', request)
      return response.data
    }
}
