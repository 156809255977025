import ComponentTranslationImportModel from '../model/ComponentTranslationImportModel'
import TranslationLanguageModel from '../model/Translation/TranslationLanguageModel'
import TranslationSummaryGridModel from '../model/Translation/TranslationSummaryGridModel'
import UpdateTranslationRequestModel from '../model/Translation/UpdateTranslationRequestModel'
import TranslationExportRequestModel from '../model/TranslationExportRequestModel'
import { ApiUrl } from '../util/api.url'
import { BaseService } from './base.service'

export class TranslationService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/Translation/'

    static async GetAllComponentTranslationLanguages (): Promise<Array<TranslationLanguageModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetAllComponentTranslationLanguages')
      return response.data
    }

    // eslint-disable-next-line max-len
    static async GetComponentTranslationSummaryByComponentIdAndContextId (componentId:number, contextId:number) :Promise<Array<TranslationSummaryGridModel>> {
      const param = {
        componentId: componentId,
        contextId: contextId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetComponentTranslationSummaryByComponentIdAndContextId', param)
      return response.data
    }

    static async UpdateComponentTranslations (changedCommonConcepts:Array<UpdateTranslationRequestModel>) {
      const response = await this.postApiCall(this.baseUrl + 'UpdateComponentTranslations', changedCommonConcepts)
      return response.data
    }

    static async GetTranslationExport (componentId:number, contextId:number): Promise<any> {
      const request = new TranslationExportRequestModel()
      request.componentId = componentId
      request.contextId = contextId

      const response = await this.FilePostDownload(this.baseUrl + 'GetTranslationExport', request)
      return response.data
    }

    static async UploadTranslationImport (file:any, componentId:number, contextId:number): Promise<Array<ComponentTranslationImportModel>> {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('componentId', componentId.toString())
      formData.append('contextId', contextId.toString())

      const response = await this.FileUpload(this.baseUrl + 'UploadTranslationExport', formData)
      return response.data
    }
}
