import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultTemplate',[_c('template',{slot:"menu"},[_c('SmallMenu',{attrs:{"items":_vm.menuItems}})],1),_vm._v(" "),_c('template',{slot:"header"},[_c('h1',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v("\n      "+_vm._s(_vm.loginStore.selectedPage)+"\n    ")]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VTab,{on:{"click":_vm.GoToDisclaimer}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("\n        mdi-information-outline\n      ")]),_vm._v("\n      Disclaimer\n    ")],1),_vm._v(" "),_c(VTab,{on:{"click":_vm.onLogout}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("\n        mdi-logout\n      ")]),_vm._v("\n      Logout\n    ")],1)],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c(VCol,{staticClass:"pt-0 pb-0"},[_c('nuxt')],1)],1),_vm._v(" "),_c(VSnackbar,{attrs:{"timeout":_vm.errorPopUpTimer,"color":"red accent-2"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.showErrorPopup = false}}},'v-btn',attrs,false),[_vm._v("\n        Close\n      ")])]}}]),model:{value:(_vm.showErrorPopup),callback:function ($$v) {_vm.showErrorPopup=$$v},expression:"showErrorPopup"}},[_vm._v("\n    "+_vm._s(_vm.errorPopUpText)+"\n\n    ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }