import _ from 'lodash'

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class ErrorMessageModel {
  private _message: ValueOrFactory<string>
  private _fullErrorMessage: ValueOrFactory<string>

  get message (): string {
    return this.getValue(this._message)
  }

  get fullErrorMessage (): string {
    return this.getValue(this._fullErrorMessage)
  }

  constructor (init?: PartialValueOrFactory<ErrorMessageModel>) {
    (Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T> (value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
