import Axios from 'axios'
import UserApplicationRoleModel from '../model/UserApplicationRoleModel'
import UserRoleSurveyModel from '../model/UserRoleSurveyModel'
import { ApiUrl } from '../util/api.url'
import { BaseService } from './base.service'
import UserModel from '~/shared/model/UserModel'

export class UserService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/User/'

    static async getUsers (): Promise<Array<UserModel>> {
      const response = await this.getApiCall(this.baseUrl + 'GetUsers')
      return response.data
    }

    static async addUser (user: UserModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'AddUser', user)
      return response.data
    }

    static async deleteUser (userId: number): Promise<void> {
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteUser?userId=' + userId, null)
      return response.data
    }

    static async updateUser (user: UserModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'UpdateUser', user)
      return response.data
    }

    static async getUserDetails (userId: number): Promise<UserModel> {
      const response = await this.getApiCall(this.baseUrl + 'GetUserDetails?userId=' + userId)
      return response.data
    }

    static async addApplicationRoleForUser (userApplicationRoleModel: UserApplicationRoleModel): Promise<void> {
      const response = await this.postApiCall(this.baseUrl + 'AddApplicationRoleForUser', userApplicationRoleModel)
      return response.data
    }

    static async checkIfUsernameAlreadyExists (username: string): Promise<boolean> {
      const response = await this.getApiCall(this.baseUrl + 'CheckIfUsernameAlreadyExists?username=' + username)
      return response.data
    }
}
