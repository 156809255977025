import { IDwhDataOptions } from '@fb-it/vue-dwh/src/types/DwhDataOptions'
import { ICdpCommonConceptList } from '@fb-it/vue-dwh/src/types/common-concepts/CdpCommonConceptList'
import { AddcommonConceptRequestModel } from '../model/classifcationManager/AddcommonConceptRequestModel'
import { CommonConceptsAndReferencesRequest } from '../model/classifcationManager/CommonConceptsAndReferencesRequest'
import { DWHCommonConceptModel } from '../model/classifcationManager/DWHCommonConceptModel'
import { ApiUrl } from '../util/api.url'
import { BaseService } from './base.service'
import { DwhCommonConceptList } from '~/shared/model/classifcationManager/DwhCommonConceptList'
import ClassificationRequestModel from '~/shared/model/ClassificationRequestModel'

export class CommonConceptService extends BaseService {
    private static baseUrl = ApiUrl.webapiurl() + '/CommonConcept/'

    static async GetCommonConcepts (options: IDwhDataOptions): Promise<DwhCommonConceptList> {
      const response = await this.postApiCall(this.baseUrl + 'GetCommonConcepts', options)
      return response.data
    }

    static async GetCommonConceptsAndReferences (request: CommonConceptsAndReferencesRequest): Promise<Array<DWHCommonConceptModel>> {
      const param = {
        skip: request.skip,
        contextId: request.contextId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetCommonConceptsAndReferences', param)
      return response.data
    }

    static async GetCommonConceptsAndReferences2 (skip:number, contextId:number): Promise<Array<DWHCommonConceptModel>> {
      const param = {
        skip: skip,
        contextId: contextId
      }
      const response = await this.getApiCallWithParam(this.baseUrl + 'GetCommonConceptsAndReferences', param)
      return response.data
    }

    static async CreateNewCommonConcept (request:AddcommonConceptRequestModel): Promise<number> {
      const response = await this.postApiCall(this.baseUrl + 'SaveCommonConcept', request)
      return response.data
    }

    static async GetCommonConceptsByClassificationId (classificationId: number): Promise<DwhCommonConceptList> {
      const request = {
        classificationId: classificationId,
        contextId: 1
      } as ClassificationRequestModel
      const response = await this.postApiCall(this.baseUrl + 'GetCommonConceptsByClassificationId', request)
      return response.data
    }

    static async GetCommonConceptList (): Promise<ICdpCommonConceptList> {
      const response = await this.getApiCall(this.baseUrl + 'GetCommonConceptsForCommonConceptManagerGrid')
      return response.data
    }

    static async DeleteCommonConcept (commonConceptId:number): Promise<void> {
      const param = {
        commonConceptId: commonConceptId
      }
      const response = await this.deleteApiCall(this.baseUrl + 'DeleteCommonConceptyById', param)
      return response.data
    }
}
