import _ from 'lodash'
import { ErrorMessageModel } from './ErrorMessageModel'

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class ErrorModel {
  private _errorCode: ValueOrFactory<number>
  private _message: ValueOrFactory<ErrorMessageModel>

  get errorCode (): number {
    return this.getValue(this._errorCode)
  }

  get message (): ErrorMessageModel {
    return this.getValue(this._message)
  }

  constructor (init?: PartialValueOrFactory<ErrorModel>) {
    (Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T> (value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
